.ViewProfileGroup{
  
  .TabBar{
    position: sticky;
    top: 0;
  }

  .ViewProfileGroup__tabSection{
    padding-top: 2rem;
    padding-bottom: 4rem;
    @include mediaQuery('sm'){
      padding-top: 4rem;
      
    }

    .ViewProfileGroup__about{
      @include mediaQuery('lg'){
        display: flex;
      }
      
      .ViewProfileGroup__aboutSidebar-wrapper{ // need this wrapper for the sticky to work. flex makes this wrapper full height of parent, so sticky cannot be applied to this, hence we have a child div which contains all the elements, on which the sticky is applied.
        border-bottom: 1px solid $greyColor10;
        padding-bottom: 4rem;

        @include mediaQuery('lg'){
          width: 30rem;
          flex: 0 0 auto;
          border: none;
          padding-bottom: 0;
        }
        
        

        .ViewProfileGroup__aboutSidebar{
          position: sticky;
          top: 9rem; //tabbar + padding top of 4rem
          
          display: flex;
          flex-direction: column;
          align-items: center;

          @include mediaQuery('lg'){
            display: block;
          }

          .kp-tpl-block{

            .title{ //reserved class
              @include titleSerif--M();
            }
            
            .admin{ //reserved class, has to be applied to the admin component
              padding-top: 4rem;
              .kp-labels-wrapper{
                padding-bottom: 1rem;
              }
            }

            .metaSecondary{
              padding-top: 1rem;
            }
        
          }

          .ViewProfileGroup__actions{
            margin-top: 4rem;
            @include mediaQuery--lessThan('lg'){
              display: flex;                
            }

            .button{
              width: 20rem;
              margin-right: 1rem;
              &:last-of-type{
                margin-right: 0;
              }
              @include mediaQuery('lg'){
                margin-bottom: 1rem;
                &:last-of-type{
                  margin-bottom: 0;
                }
              }
            }
          }
        } 
      }
      
      .ViewProfileGroup__aboutBody{
        margin-top: -2rem;
        margin-bottom: -2rem;
        padding-right: 0;
        padding-top: 4rem;
        width: 100%;

        @include mediaQuery--lessThan('lg'){
          padding-left: 0;
        }

        @include mediaQuery('lg'){
          padding-top: 0;
        }

        .kp-tpl-block{
          padding-top: 2rem;
          padding-bottom: 2rem;
          .kp-rich-editor{
            @include bodySans--M();
            .li{
              @include bodySans--M();
            }
          }
        }
      }
    }
  }
}

// .ViewProfileGroup {
//   width: 100%;
//   position: relative;
// }
// .ViewProfileGroup__TopSection {
//   position: relative;
//   width: 100%;

//   img {
//     width: 100%;
//     height: 314px;
//     object-fit: cover;
//   }
// }

// .ViewProfileGroup__MiddleSection {
//   position: relative;
//   width: 100%;
//   height: 6rem;
//   border-bottom: 1px solid #e1e1e1;

//   display: flex;

//   @include mediaQuery("xs") {
//     padding: 0 2rem;
//   }
//   @include mediaQuery("md") {
//     padding: 0 4rem;
//   }
//   @include mediaQuery("lg") {
//     padding: 0 6rem;
//   }
//   @include mediaQuery--lessThan("md") {
//     overflow-x: scroll;
//   }
// }

// .ViewProfileGroup__MiddleSection {
//   &::-webkit-scrollbar {
//     height: 0 !important;
//     width: 0;
//   }
//   overflow: -moz-scrollbars-none;
//   -ms-overflow-style: none;
// }
// .ViewProfileGroup__BottomSection {
//   position: relative;
//   display: flex;
//   padding: 4rem 0;
//   // background: #444;
//   .BottomSection__left {
//     // width: 30%;
//     display: flex;
//     justify-content: flex-start;
//     flex-direction: column;
//     @include oke-col(3, "lg");
//     @include oke-col(2, "md");
//   }
//   .BottomSection__right {
//     width: 600px;
//     @include oke-col(7, "lg");
//     @include oke-col(4, "md");
//   }
// }
