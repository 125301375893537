.kp-faq-comp{
  .kp-faq-blocks-wrapper{
    margin: -0.5rem 0; //negating the padding on first & last kp-faq-input wrapper
  }
  .add-new-block-btn-wrapper{
    @include kp-separator-top;
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .add-new-block-btn{
      transform: translateY(-50%);
    }
  }


}
