//format toolbar
.kp-rich-format-toolbar-wrapper {
  // position: fixed;
  // bottom: 0;
  // left: 0;
  width: 100%;

  // wrote by rahul
  position: relative;

  @include media768() {
    position: relative;
    // margin-left: -2rem;
    // margin-right: -2rem;
    // width: auto;

    // wrote by rahul
    width: 100%;
    margin-top: 2rem;
  }
}

.kp-rich-format-toolbar {
  position: relative;
  justify-content: space-between;
  // margin-top: 2.5rem;
  // padding: 0.5rem 2rem;
  background-color: $greyColor100;


  width: 100%;
  z-index: 100;
  // overflow: scroll;

  // wrote by rahul
  justify-content: flex-start;


  @include media768() {
    position: relative;
    overflow: visible;
    width: auto;
  }

  .kp-x-scroll-indicator {
    position: fixed;
    right: 0;
    background: $greyColor80;
    bottom: 0;
    height: 5rem;
    z-index: 100;
    display: flex;
    align-items: center;

    @include media768() {
      display: none;
    }
  }




  .kp-rich-format-toolbar__adv-block-menu-btn {
    height: 5rem;
    border-radius: 0;
    padding-right: 1.2rem;
    color: $white;
    border: 0;

    &.selected {
      background-color: $greyColor80;
    }

    &::after {
      background-color: $greyColor80
    }

    ;

  }


}

.kp-rich-format-toolbar__tool-wrapper {
  position: relative;
}

//we havnt nested this so that it affects inline toolbar as well.
.kp-rich-format-toolbar,
.kp-inline-toolbar {
  .kp-rich-format-toolbar__tool {
    background-color: $greyColor100;
    // padding: 0.8rem 1.6rem;
    width: 5rem;
    height: 5rem;

    //kp-icon-btn overrides
    &.kp-icon-btn {
      &::before {
        background-color: $greyColor100;
        border-radius: 0
      }

      &.selected::before {
        background-color: $greyColor80;
      }

      &::after {
        background-color: $greyColor80;
      }

      

      &:hover {
        &::after {
          border-radius: 0
        }
      }
    }


  }
}

.kp-advanced-blocks-list {
  position: absolute;
  right: 0;
  bottom: 5rem;
  color: $white;
  background-color: $greyColor100;
  width: 100%;
  max-height: 250px;
  overflow: scroll;
  padding: 2rem 0;

  @include media768() {
    max-width: 400px;
    // top: 0;

  }

  .kp-adv-blocks-list-items-wrapper {
    margin: -2rem 0;

    .kp-adv-blocks-list-item,
    .kp-simple-blocks-list-item {
      cursor: pointer;

      border-bottom: 1px solid $greyColor80;
      background-color: $none;
      @include kp-transition(background-color);

      .kp-simple-blocks-btn{
        border: 0;
        color: $white;
      }

      &:hover {
        background-color: $greyColor80
      }
    }
  }

  .kp-adv-blocks-list-item {
    padding: 1rem 2rem;

    .EditorToolbar__toolSubtitle{
      padding-top: 0.5rem;
    }
  }

  .kp-simple-blocks-list-item {
    .kp-simple-blocks-btn {
      //some hacky stuff to make this seem like the adv block list items...
      width: 100%;
      border-radius: 0;
      background-color: $none;
      justify-content: left;

      &::before {
        background-color: $none;
      }
    }
  }



}


.KP-kebab-menu__toolbar-and-advance-blocks {
  height: 13rem;
  background-color: #262E33;
  position: absolute;
  bottom: 5rem;
  left: 0;
  right: 5%;
  overflow-y: scroll;
  padding: 2rem;
  padding-bottom: 0;

  &::-webkit-scrollbar {
    width: 8px;
    height: 100%;
    background: #262E33;
  }

  &::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    // border-radius: 10px;
    background: #262E33;
    height: 100%;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 5rem;
    border-radius: 5px;
    // box-shadow: inset 0 0 6px#85939A;
    background: #85939A
  }

  &::-webkit-scrollbar-corner {
    width: 100%;
  }
}

.KP-kebab-menu__toolbar-simpleblock-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  // padding-bottom: 1rem;
  .KP-kebab-menu__toolbar-group__label {
    &--name {
      font-size: 1.6rem;
      color: #707070;
    }

    &--line {
      width: 100%;
      height: 1px;
      background: #707070;
    }
  }

  .KP-kebab-menu__toolbar-group--list {
    display: flex;
    flex-direction: row;
    padding: 1.5rem 0;
    padding-bottom: 1rem;
    flex-wrap: wrap;

    .kp-rich-format-kebab-menu-toolbar__tool-wrapper {
      position: relative;

      .kp-rich-format-kebab-menu-toolbar__tool-tooltip {
        color: #E2E2E2;
        background: #e2e2e267;
        position: absolute;
        top: -1.5rem;
        left: 50%;
        transform: translateX(-50%);
        padding: 0 .5rem;
        font-size: 1rem;
        z-index: 22;
      }

      .kp-rich-format-toolbar-kebabMenu__tool {
        &:before {
          background-color: $none !important;
        }

        &::before {
          border-radius: 0 !important;

        }

        &::after {
          border-radius: 0 !important;
          background-color: #3A474E !important;
        }

        &:hover {
          &::after {
            width: 100%;
            height: 100%;

            // background-color: #3A474E !important;
          }
        }
      }
    }
  }
}


.KP-kebab-menu__toolbar-Advance-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 1.5rem;

  .KP-kebab-menu__toolbar-group__label {
    &--name {
      font-size: 1.6rem;
      color: #707070;
    }

    &--line {
      width: 100%;
      height: 1px;
      background: #707070;

    }
  }

  .kp-adv-blocks-list-item {
    position: relative;
    cursor: pointer;
    padding: 1rem 0;
    border-bottom: 1px solid $greyColor80;
    background-color: $none;
    color: #E2E2E2;
    z-index: 3000;

    @include kp-transition(background-color);

    // &:hover {
    //   background-color: $greyColor80;


    // }

    &:hover .kp-adv-blocks-list-item__hover-cover {
      position: absolute;
      top: 4px;
      left: -10px;
      width: 108%;
      height: 93%;
      background-color: $greyColor80;
      padding: 0 1rem;
      z-index: -1;
    }
  }
}