$contentSectionPrimaryMinWidth: 40rem;
$contentSectionPrimaryHeight: 30rem;

$contentSectionSecondaryMinWidth: 31rem;
$contentSectionSecondaryHeight: 23rem;

.kp-content-card-wrapper.content-section--primary{

    min-width: $contentSectionPrimaryMinWidth;

    .kp-content-card{
      height: $contentSectionPrimaryHeight;
      background-color: $none;

      @include media576(){

      }

      .kp-content-card__text-wrapper{
        height: 100%;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .kp-content-card__title{
          padding-top: 1rem;
          @include kp-clamp-text(4);
          @include sans-serif();
          @include h2(); //this whole thing needs to be updated...
          @include weight-semibold();
        }
      }
    }
}


.kp-content-card-wrapper.content-section--secondary{

    min-width: $contentSectionSecondaryMinWidth;

    .kp-content-card{
      background-color: $none;

      @include media576(){
        height: $contentSectionSecondaryHeight;
      }

      .kp-content-card__text-wrapper{
        height: 100%;
        padding: 2rem;

        .kp-content-card__title{
          padding-top: 1rem;
          @include kp-clamp-text(3);
          @include sans-serif();
          @include h3();
          @include weight-semibold();
        }
      }
    }
}
