.UpdatesModule { //this is only for updates being displayed on the published page. NOT the contribute version

  @include oke-col-0();
  @include oke-offset(1, "lg");
  @include oke-col(10, "lg");
  @include oke-offset(3, "xl");
  @include oke-col(6, "xl");

  .Update--published{
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow: hidden;

    @include oke-negative-hor-margin('xs');
    @include oke-negative-hor-margin('sm');
    @include oke-negative-hor-margin('md');
    @include oke-negative-hor-margin('lg');

    

    &.accordion-closed{
      height: 8.3rem;
    }

    &.accordion-open{
      height: auto;
    }
    
      .Tpl__introSection-2, .Tpl__bodySection{
        @include pageContainer();
        background-color: $greyColor5;
      }

      .Tpl__introSection-2{
        @include kp-transition(background-color);

        &:hover{
          background-color: $greyColor10;
        }
      }

      .Tpl__introSection-2 {
        cursor: pointer;

        .Tpl__introSection-2-container {
          

          .Tpl__introSection-2-row {
            

            .Tpl__block {
              
            }

            .TplMetaBlock{
              border-top: none;

              .TplMetaBlock__text {
                .text{
                  @include bodySans--M--Bold();
                  
                }
              }
            }

          }
        }
      }

      .Tpl__bodySection {
        
        padding-top: 6rem;
        padding-bottom: 6rem;
        
        .Tpl__subsection__{
          width: 100%;
        }

        .Tpl__bodySection-container {
          
          overflow: visible;
          .Tpl__bodySection-row {
            
            margin-top: -3rem;
            margin-bottom: -3rem;

            .Tpl__block,
            .Tpl__subsectionTitleModule {
            
              padding-top: 3rem;
              padding-bottom: 3rem;

              &.conditional {
                background-color: $greyColor5;
              }

              &.blockError {
                background-color: $xLightRed;
              }

            }

            .Tpl__subsectionTitleModule {
              padding-bottom: 0;
            }

            .Tpl__block--summary {

              &::after{
                content: '';
                display: block;
                width: 100%;
                border-bottom: 1px solid $greyColor15;  
                padding-top: 2rem;
                padding-bottom: 2rem;
              }
              
              span {
                @include weight-normal();
                @include summary();
                font-size: 1.75rem;
              }
            }
            .Tpl__block.Tpl__block--summary:last-of-type{
              padding-bottom: 0;
              border: none;
            }
          }
        }
      }

      .Tpl__tagSection {
        border-top: 1px solid $greyColor15;
        border-bottom: 1px solid $greyColor15;
        padding-top: 4rem !important;
        padding-bottom: -4rem;
        background: $greyColor5;
        @include pageContainer();

        .Tpl__block {
          @include oke-offset(1, "lg");
          @include oke-col(10, "lg");
          @include oke-col(6, "xl");
          @include oke-offset(3, "xl");
          margin-bottom: 4rem;
        }

        // community tag
        .kp-dynamic-platform-resource-input-container {
          margin-top: 1rem;
          margin-bottom: 4rem;
          margin-left: -0.6rem;
          .kp-dynamic-platform-resource--display {
            display: flex;
            .kp-dy-platform-resource__selected-comp {
              border: 1px solid #e1e1e1;
              border-radius: 2rem;
              padding-left: 0.7rem;
              margin-bottom: 1rem;
              margin-right: 1rem;
            }
          }
        }
        // simple tag
        .kp-dynamic-tags-input-wrapper {
          // temp fix
          margin-left: -0.6rem;

          .kp-tags-selected-comp {
            margin-left: 0;
            &__text {
              font-size: 1.4rem;
            }
          }
        }
        .kp-dynamic-search-input-container {
          margin-left: 0rem;
          .kp-dynamic-search-input{
            @include inputText()
          }
        }

        .kp-input-wrapper {
          border-bottom: 1px solid $primaryColor100;
        }
      }
  }

}
