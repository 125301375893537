.TabBar{
  border-bottom: 1px solid $greyColor10;
  height: 5rem;
  background-color: $white;
  z-index: 1000;

  .TabBar-container{
    @include pageContainer();
    height: 100%;
    
    .TabBar-row{
      display: flex;
      margin-left: -1.5rem;
      margin-right: -1.5rem; 
      height: 100%; 

      .TabBar__tab-wrapper{
        height: 100%;
        @include kp-transition(background-color);
        &:hover{
          background-color: $primaryColor10;
        }
        &.-active-{
          position: relative;
  
          &::after{
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: $primaryColor100;
          }
  
        }
  
        .TabBar__tab{
          @include normalizeButton();
          padding-top: 1.5rem;
          padding-left: 1.5rem;
          padding-right: 1.5rem;
          height: 100%;
          @include label--M();
          
        }
      }
    }
    
    
    
  }

  
}