
.OKE-Tpl--plans,
.OKE-Tpl--demographics,
.OKE-Tpl--DGMC,
.OKE-Tpl--DGHV,
.OKE-Tpl--DGME,
.OKE-Tpl--FGDYM,
.OKE-Tpl--IDIYM,
.OKE-Tpl--IDIFM,
.OKE-Tpl--IDIGD,
.OKE-Tpl--IDIAHE,
.OKE-Tpl--COGAMEYM
.OKE-Tpl--caseReports
{

  .Tpl__bodySection{
    // padding: 4rem 0;
    
    

      
        .Tpl__block{
          // border-bottom: 1px solid $greyColor10;
          // > div {
          //   padding: 2rem 0;
            
          // }

          .kp-labels-wrapper{
            padding-bottom: 2rem;
          }

          .sublabel{
            // font-style: normal;
          }
          .kp-label__text{
            @include titleSerif__S();
            @include sans-serif();
            @include weight-bold();
            text-transform: none;
            @include ls-0();
            // @include weight-normal(); 
            color: $greyColor100;
            width: 100%;
          }
        }
      
    
  }
}
