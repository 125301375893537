.kp-multi-profile-cards-container{
  &.input-comp{
    @include kp-dashed-border();
  }

  margin: 0 -2rem;

  .kp-remove-profile-card__action-btn{
    position: absolute;
    top: 50%;
    right: 0rem;
    transform: translateY(-50%);
    z-index: 10;
  }

  
  .kp-add-profile-card__action-btn{
    
    // color: $primaryColorText;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
  

}
