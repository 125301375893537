
  // .TabBar{
  //     position: sticky;
  //     top: 0;
  //   }
  .ViewProfileUser__profileSection{
    background-color: $greyColor100;

    .ViewProfileUser__profileSection-container{
      padding-top: 2rem;
      padding-bottom: 4rem;
      @include mediaQuery('sm'){
        padding-top: 4rem;
      }

      .ViewProfileUser__about{
        @include mediaQuery('lg'){
          display: flex;
        }
        
        .ViewProfileUser__aboutSidebar-wrapper{ //maybe this wrapper is unnecessary. we put it only to make position sticky work, but  i dont think we need it to make sticky work
          border-bottom: 1px solid $greyColor80;
          padding-bottom: 4rem;

          @include mediaQuery('lg'){
            width: 30rem;
            flex: 0 0 auto;
            border: none;
            padding-bottom: 0;
          }
          
          

          .ViewProfileUser__aboutSidebar{
            position: sticky;
            top: 4rem; //padding top of 4rem
            
            display: flex;
            flex-direction: column;
            align-items: center;

            // @include mediaQuery('lg'){
            //   display: block;
            // }

            .kp-tpl-block{

              .avatar{
                width: 24rem; //reserved class
              }

              &.kp-tpl-block--title, &.kp-tpl-block--org, &.kp-tpl-block--desig{
                @include mediaQuery('lg'){
                  display: none;
                }
              }
              

              .title{ //reserved class
                @include titleSerif--M();
                padding-top: 1rem;
                color: white;
              }

              .metaSecondary{
                padding-top: 1rem; //mobile its padding:1 cuz title comes on top

                @include mediaQuery('lg'){
                  padding-top: 2rem;
                }
              }

              &.kp-tpl-block--org{
                .kp-meta-primary{
                  padding-top: 1rem;
                }
              }

              &.kp-tpl-block--desig{
                .metaSecondary{
                  padding-top: 0;
                }
              }
              
              .admin{ //reserved class, has to be applied to the admin component
                padding-top: 4rem;
                .kp-labels-wrapper{
                  padding-bottom: 1rem;
                }
              }

              .contact{ //reserved class
                padding-top: 4rem;

                .kp-like-contact-channels--display__exposed-link-group{
                  display: flex;
                  flex-direction: column;

                  // @include mediaQuery('lg'){
                  //   display: block;
                  // }
                }

                .kp-like-contact-channels--display__icons-group{
                  justify-content: center;
                }
              }

             
          
            }

            .ViewProfileUser__actions{
              margin-top: 4rem;
              @include mediaQuery--lessThan('lg'){
                display: flex;                
              }
              
              .button{
                width: 20rem;
                margin-right: 1rem;
                &:last-of-type{
                  margin-right: 0;
                }
                @include mediaQuery('lg'){
                  margin-bottom: 1rem;
                  &:last-of-type{
                    margin-bottom: 0;
                  }
                }
              }
            }
          } 
        }
        
        .ViewProfileUser__aboutBody{
          margin-top: -2rem;
          margin-bottom: -2rem;
          padding-right: 0;
          padding-top: 4rem;
          color: $white;
          width: 100%;

          @include mediaQuery--lessThan('lg'){
            padding-left: 0;
          }

          @include mediaQuery('lg'){
            padding-top: 0;
          }

          .ViewProfileUser__aboutBodyContentsMask{
            @include kp-transition(height);
          }

          .kp-tpl-block{
            padding-top: 2rem;
            padding-bottom: 2rem;

            .title{ //reserved class
              @include titleSerif--M();
              
              
            }

            &.kp-tpl-block--title{
              @include mediaQuery--lessThan('lg'){
                display: none;
              }
            }
            &.kp-tpl-block--org, &.kp-tpl-block--desig{ 
              padding-top: 0;
              margin-top: -2rem;

              .kp-meta-primary{
                padding-top: 1rem;
              }

              @include mediaQuery--lessThan('lg'){
                display: none;
              }
            }

            

            .kp-rich-editor{
              @include bodySans--M();
              .li{
                @include bodySans--M();
              }
            }
          }
        }
      }
    }
  } 



// .ViewProfileUser {
//   width: 100%;
//   position: relative;
// }

// .User__TopSectionWrapper {
//   overflow: hidden;
//   display: flex;
//   flex-wrap: wrap;
//   padding: 6rem 0;
//   color: white;
//   transition: all 300ms ease;
//   background-color: #383838;

//   .User__left {
//     padding-bottom: 2rem;
//     width: 100%;
//     display: flex;
//     align-items: center;
//     flex-direction: column;

//     .kp-tpl-block {
//       margin-top: 1.5rem;
//       .org-and-desig-display {
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         .link {
//           padding: 0;
//           margin-bottom: 0.5rem;
//           .kp-link-content__text {
//             color: $primaryColor40;
//             letter-spacing: 10%;
//           }

//           &:hover {
//             background-color: transparent;
//           }
//         }

//         .kp-text-input-display {
//           font-weight: normal;
//           color: $greyColor15;
//           text-align: center;
//           span {
//             padding: 0 !important;
//           }
//         }
//       }
//     }
//     .user-avatar {
//       width: 15rem;
//       height: 15rem;
//       border-radius: 50%;
//       margin-top: -1.5rem;
//     }
//     .location {
//       .kp-meta-block {
//         padding: 0;
//       }
//     }
//     @include oke-col(2, "md");
//     @include oke-col(3, "lg");
//   }

//   .User__right {

//     @include oke-col(4, "md");
//     @include oke-col(7, "lg");

//     .kp-tpl-block {
//       margin-bottom: 2rem;
//       .kp-label__text {
//         color: $greyColor40;
//       }
//       .org-and-desig-display {
//         .link {
//           padding: 0;
//           margin-bottom: 0.5rem;

//           .kp-link-content__text {
//             color: $primaryColor40;
//             letter-spacing: 10%;
//           }

//           &:hover {
//             background-color: transparent;
//           }
//         }

//         .kp-text-input-display {
//           font-weight: normal;
//           color: $greyColor15;
//         }
//       }

//       .primaryBio {
//         span {
//           line-height: 160%;
//           @include bodySans--L();
//         }
//       }
//     }

//     @include mediaQuery("md") {
//       padding: 0 4rem;
//     }
//   }

//   .User__expandBtn {
//     color: $primaryColor40;
//     text-decoration: underline;
//     cursor: pointer;
//     margin-top: 3rem;
//     font-size: 1.6rem;
//     line-height: 160%;
//   }
// }
