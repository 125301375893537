.kp-inline-img-wrapper{
  position: relative;
  margin-left: -2rem;
  margin-right: -2rem;

  .kp-delete-inline-img-icon-btn{
    position: absolute;
    right: 2rem;
    top: 2rem;
  }
}
