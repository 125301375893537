
.kp-alerts-wrapper{
  position: fixed;
  top: 10rem;
  width: 100%;
  z-index: 100000000000;

  .kp-alert{
    position: relative;
    display: flex;
    align-items: center;
    padding: 0.5rem 2rem 0.5rem 2rem;
    background-color: $greyColor10;

    @include media768(){
      padding: 0.5rem 6rem;
      justify-content: center;
    }

    .kp-alert__icon-wrapper{
      padding-right: 2rem;
    }

    .Alert__text{
      // background-color: $white-opacity50;
      margin-right: 4rem;

      @include media768(){
        margin-right: 0;
      }
    }

    .kp-alert__remove-alert-btn{
      position: absolute;
      right: 2rem;
    }
  }
}

.kp-alert--danger {
  
  // background-image: repeating-linear-gradient(-45deg,
  //   $red,
  //   $red 1px,
  //   $white-opacity80 1px,
  //   $white-opacity80 6px
  //   );
  .Alert__text{
    color: $red
  }
}

.kp-alert--success {
  // background-image: repeating-linear-gradient(-45deg,
  //   $green,
  //   $green 1px,
  //   $white-opacity80 1px,
  //   $white-opacity80 6px
  //   );
  .Alert__text{
    color: $green
  }
}

.kp-alert--neutral {
  // background-image: repeating-linear-gradient(-45deg,
  //   $yellow,
  //   $yellow 1px,
  //   $white-opacity80 1px,
  //   $white-opacity80 6px
  //   );

    .Alert__text{
      color: $greyColor100
    }
}
