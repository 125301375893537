.FilterModule__searchAndSort-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  overflow: unset;

  .FilterModule__sortLabel{
    color: $greyColor40;
    padding-right: 1rem;
  }

  .FilterModule__sortDropdown{

    .OKE-Dropdown__Select{
      min-width: 20rem;
    }
    
  }
}