.kp-faq-single-block{
  // padding-top: 0.5rem;
  // padding-bottom: 0.5rem;
  overflow: hidden;
  @include kp-transition-2(height, 0.3s);
  @include kp-separator-bottom();

  &:first-of-type{
    // padding-top: 0;
  }

  &:last-of-type{
    // padding-bottom: 0;
    border: 0;
  }

  .kp-faq-input-wrapper{
    padding: 0.5rem 0;
  }
}
