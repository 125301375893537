.playground-area{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 2rem;
  .bg{
    width: 50%;
    padding: 50px;
    display: flex;
    flex-wrap: wrap;
  }
  .dark{
    background-color: #383838;
  }
}