.kp-data-card-header{
  display: flex;
  align-items: center;
  border-bottom: 1px solid $greyColor15;
  padding: 1rem 2rem;

  @media ( min-width: 768px){
    padding: 2rem 3rem;;
  }

  @media ( min-width: 1366px){
    padding: 2rem 4rem;;
  }

  .kp-data-card-header__title{
    color: $greyColor70;
    padding-left: 2rem;
  }
}
