.TplMetaBlock {
  // margin-top: 4rem;
  border-top: 1px solid $greyColor80;
  padding: 2rem 0;
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  .kebabMenu{
    position: relative;

    .kebabMenu__options{
      position: absolute;
      right : 0;
      padding: 0.5rem 1rem;
      background-color: $white;
      @include box-shadow-1();
      z-index: 100;
    }
  }

  .TplMetaBlock__text {
    .text {
      // color: #e1e1e1;
    }
  }
  .TplMetaBlock__content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 800px) {
      padding-bottom: 2rem;
    }

    .kp-meta-block__separator {
      width: 1px;
      height: 12.8px;
      background-color: $greyColor15;
      margin-bottom: 0.3rem;
    }
  }

  .actionDivider {
    width: 2px;
    height: 15px;
    
    // margin-top: 5px;
  }

  .TplMetaBlock__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    .published-page__actions {
      display: flex;

      &-btn--edit,
      &-btn--moderate {
        // margin-right: 2rem;
        padding: 0 1rem 0 0;
        border: none;
        .kp-link {
          padding-left:0;
        }
        .kp-btn__icon-wrapper {
          padding-right: 0.5rem !important;
        }
      }
    }
  }
}