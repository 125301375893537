.kp-toggle-bar{
  display: flex;
  width: 100%;
  user-select: none;
  cursor: pointer;
  border: 1px solid $greyColor15;

  .kp-toggle-bar__option{
    // padding: 2rem 2rem ;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 ;

    .kp-toggle-bar__option-text{
      color: $primaryColorText;
      text-align: center;
    }

    &.selected{
      background-color: $primaryColor10;
      outline: 1px solid $primaryColor10;
    }
  }
}
