$initiativesCardHeight: 30rem;
// $InitiativesCardHeightScale: 35rem;

.kp-content-card-wrapper{

  //1
  &.published--resources{

    .kp-content-card{

      @include media768(){
        // height: $initiativesCardHeight;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .kp-content-card__content{
        height: auto;

        .kp-content-card__text-wrapper{
          .kp-content-card__title{
            @include kp-clamp-text(4);
          }
        }

      }

      @include resourceTypeCardActions($primaryColor100 , $white);

    }
  }

}
