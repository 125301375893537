
.kp-container-fluid.kp-data-summary-body{
  background-color: $greyColor10;
}


//secondary header stuff. eventually move it to its own component

.kp-secondary-header__left-comp,
.kp-secondary-header__center-comp,
.kp-secondary-header__right-comp{
  flex: 1;
}

.kp-secondary-header{
  height: 6rem;
  background-color: $white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;

  @media ( min-width: 576px){
    padding: 0 6rem;
  }

  .kp-secondary-header__left-comp{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .kp-secondary-header__right-comp{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .kp-secondary-header__title{
    color: $greyColor100;
  }

  .kp-secondary-header__subtitle, .kp-secondary-header__text{
    color: $greyColor100;
    padding: 0 2rem;

    strong{
      padding-left: 1rem;
    }
  }

  .kp-secondary-header__dd .kp-dd__select{
    width: 16rem;
  }
}
