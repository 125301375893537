.ViewProfileOrg{
    .TabBar{
        position: sticky;
        top: 0;
      }

    .ViewProfileOrg__tabSection{
      padding-top: 2rem;
      padding-bottom: 4rem;
      @include mediaQuery('sm'){
        padding-top: 4rem;
        
      }
  
      .ViewProfileOrg__about{
        @include mediaQuery('lg'){
          display: flex;
        }
        
        .ViewProfileOrg__aboutSidebar-wrapper{ //maybe this wrapper is unnecessary. we put it only to make position sticky work, but  i dont think we need it to make sticky work
          border-bottom: 1px solid $greyColor10;
          padding-bottom: 4rem;
  
          @include mediaQuery('lg'){
            width: 30rem;
            flex: 0 0 auto;
            border: none;
            padding-bottom: 0;
          }
          
          
  
          .ViewProfileOrg__aboutSidebar{
            position: sticky;
            top: 9rem; //tabbar + padding top of 4rem
            
            display: flex;
            flex-direction: column;
            align-items: center;

            @include mediaQuery('lg'){
              display: block;
            }
  
            .kp-tpl-block{

              .logo{
                width: 24rem;
              }

              .title{ //reserved class
                @include titleSerif--M();
                padding-top: 1rem;
              }
              
              .admin{ //reserved class, has to be applied to the admin component
                padding-top: 4rem;
                .kp-labels-wrapper{
                  padding-bottom: 1rem;
                }
              }

              .contact{ //reserved class
                padding-top: 4rem;

                .kp-like-contact-channels--display__exposed-link-group{
                  display: flex;
                  flex-direction: column;

                  @include mediaQuery('lg'){
                    display: block;
                  }
                }

                .kp-like-contact-channels--display__icons-group{
                  justify-content: center;

                  @include mediaQuery('lg'){
                    justify-content: flex-start;
                  }
                }
              }
  
              .metaSecondary{
                padding-top: 1rem;
              }
          
            }
  
            .ViewProfileOrg__actions{
              margin-top: 4rem;
              @include mediaQuery--lessThan('lg'){
                display: flex;                
              }
              
              .button{
                width: 20rem;
                margin-right: 1rem;
                &:last-of-type{
                  margin-right: 0;
                }
                @include mediaQuery('lg'){
                  margin-bottom: 1rem;
                  &:last-of-type{
                    margin-bottom: 0;
                  }
                }
              }
            }
          } 
        }
        
        .ViewProfileOrg__aboutBody{
          margin-top: -2rem;
          margin-bottom: -2rem;
          padding-right: 0;
          padding-top: 4rem;
  
          @include mediaQuery--lessThan('lg'){
            padding-left: 0;
          }
  
          @include mediaQuery('lg'){
            padding-top: 0;
          }
  
          .kp-tpl-block{
            padding-top: 2rem;
            padding-bottom: 2rem;
            .kp-rich-editor{
              @include bodySans--M();
              .li{
                @include bodySans--M();
              }
            }
          }
        }
      }
    }
  }
  