$featuredPrimaryMinWidth: 40rem;
$featuredPrimaryHeight: 30rem;

$featuredSecondaryMinWidth: 31rem;
$featuredSecondaryHeight: 23rem;

.kp-content-card-wrapper.featured--primary{

    min-width: $featuredPrimaryMinWidth;

    .kp-content-card{
      // height: $featuredPrimaryHeight;

      @include media576(){

      }

      .kp-content-card__text-wrapper{
        height: 100%;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .kp-content-card__meta-primary{
          padding-bottom: 1rem;
        }

        .kp-content-card__title{
          padding-top: 0;
          @include titleSerif--M();
          // @include kp-clamp-text(4);
          // @include sans-serif();
          // @include h2();
          // @include weight-semibold();
        }
      }
    }
}

.kp-content-card-wrapper.featured--secondary{

    min-width: $featuredSecondaryMinWidth;

    .kp-content-card{

      @include media576(){
        // height: $featuredSecondaryHeight;
      }

      .kp-content-card__text-wrapper{
        height: 100%;
        padding: 2rem;

        .kp-content-card__meta-primary{
          padding-bottom: 1rem;
        }

        .kp-content-card__title{
          // padding-top: 1rem;
          // @include kp-clamp-text(3);
          // @include sans-serif();
          // @include h4();
          // @include weight-semibold();
        }
      }
    }
}

.kp-content-card-wrapper.featured--primary-with-media, .kp-content-card-wrapper.featured--secondary-with-media{
  .kp-content-card{
    .kp-content-card__text-wrapper{
      position: relative;
      // background: $black-opacity50;

      .kp-content-card__meta-primary{
        .kp-meta-primary__text{
          color: $primaryColorText;
        }
      }

      .kp-content-card__title{
        color: $greyColor100;
      }

      .kp-content-card__meta-group{
        .kp-meta-block__meta-text{
          color: $greyColor100;
        }

        .kp-meta-block__separator{
          background-color: $greyColor5;
        }
      }
    }
  }
}

.kp-content-card-wrapper.featured--primary-with-media{

    .kp-content-card{

      .kp-video-embed{
        position: absolute;
        width: 100%;
        height: 100%;

        .kp-video-embed__display{
          width: 140%;
          margin-left: -20%;
        }
      }

      .kp-content-card__img-container{
        position: absolute;
        width: 100%;
        height: 100%;

        .kp-image-input{
          width: 128%;
          margin-left: -14%;
        }
      }

    }
}

.kp-content-card-wrapper.featured--secondary-with-media{

    .kp-content-card{

      .kp-video-embed{
        position: absolute;
        width: 100%;
        height: 100%;

        .kp-video-embed__display{
          width: 140%;
          margin-left: -20%;
        }
      }

      .kp-content-card__img-container{
        position: absolute;
        width: 100%;
        height: 100%;

        .kp-image-input{
          width: 128%;
          margin-left: -14%;
        }
      }

    }
}
