

// $homeResourcesCardMinWidth: 20rem;


.kp-content-card-wrapper.home--news{
    padding: 0;

    .kp-content-card{
      border-radius: 0;
      border: 0;
      background-color: $none;
      // border-color: black;
      // border-bottom: 1px solid $white;
      @include no-box-shadow();

      &:hover{
        @include box-shadow-2();
      }

      @include media576(){
        height: $homeHorSection__NewsCardHeight;
      }

      .kp-content-card__content{
        // display: flex;
        // align-items: center;
        .kp-content-card__img-container{
          padding: 1rem;
          padding-bottom: 0;
        }

        .kp-content-card__text-wrapper{
          // height: 100%;
          padding: 2rem 1rem ;
          position: relative;

          .kp-content-card__title{
            padding-top: 1rem;
            // @include kp-clamp-text(2);
            // @include sans-serif();
            @include titleSerif--S();
            // @include weight-semibold();
          }

          .kp-meta-primary{
            padding-right: 4rem;
          }

          .kp-content-card__meta-secondary{
            @include kp-clamp-text(1);
            @include h6();
          }

          .kp-content-card__meta-group{
            @include resourceTypeCardActions($white, $primaryColor100);

            .kp-content-card__actions-contents-wrapper{
              position: absolute;
              top: 1rem;
              right: 1rem;
            }
          }


          .kp-meta-block-contents-wrapper{
            padding-top: 1.5rem;

            .kp-meta-block__meta-wrapper{
              max-width: 100%;
            }
          }

        }
      }


    }
}

.kp-content-card-wrapper.home--resources{
    padding: 0;
    // min-width: $homeResourcesCardMinWidth;

    .kp-content-card{
      border-radius: 0;
      border: 0;
      background-color: $none;
      @include no-box-shadow();

      &:hover{
        @include box-shadow-2();
      }

      @include media576(){
        height: $homeHorSection__ResourcesCardHeight;
      }

      .kp-content-card__content{
        // display: flex;
        // align-items: center;
        height: auto;

        .kp-content-card__text-wrapper{
          // height: 100%;
          padding: 2rem 1rem ;
          position: relative;

          .kp-meta-primary{
            padding-right: 4rem;
            padding-bottom: 1rem;
          }

          .kp-content-card__title{
            padding-top: 0;
            // @include kp-clamp-text(3);
            // @include sans-serif();
            // @include h4();
            // @include weight-semibold();
            @include titleSerif--S();
          }

          .kp-content-card__meta-group{
            @include resourceTypeCardActions($white, $primaryColor100 );
          }

          .kp-meta-block-contents-wrapper{
            padding-top: 1.5rem;

            .kp-meta-block__meta-wrapper{
              max-width: 100%;
            }
          }

        }


      }

      @include resourceTypeCardActions($none, $primaryColor100 );

      .kp-content-card__actions-contents-wrapper{
        position: absolute;
        top: 1rem;
        right: 1rem;
      }

    }
}

.kp-content-card-wrapper.home--tools{
    padding: 0;
    // min-width: $homeResourcesCardMinWidth;

    .kp-content-card{
      border-radius: 0;
      border: 0;
      background-color: $none;
      @include no-box-shadow();

      &:hover{
        @include box-shadow-2();
      }

      @include media576(){
        height: $homeHorSection__ToolsCardHeight;
      }

      .kp-content-card__content{
        height: 100%;

        .kp-content-card__text-wrapper{
          padding: 2rem 1rem ;
          position: relative;
          height: 100%;

          .kp-content-card__title{
            padding-top: 0;
            @include kp-clamp-text(1);
            @include sans-serif();
            @include h4();
            @include weight-bold();
          }

          .kp-content-card__summary{
            padding-top: 0.5rem;
            @include kp-clamp-text(2);
            @include sans-serif();
            @include h4();
            @include weight-medium();
          }

        }
      }
    }
}
