
@font-face {
    font-family: 'noto_sans';
    src: url('../fonts/notosans-bold-webfont.woff2') format('woff2'),
         url('../fonts/notosans-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}


@font-face {
    font-family: 'noto_sans';
    src: url('../fonts/notosans-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/notosans-bolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}


@font-face {
    font-family: 'noto_sans';
    src: url('../fonts/notosans-italic-webfont.woff2') format('woff2'),
         url('../fonts/notosans-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}


@font-face {
    font-family: 'noto_sans';
    src: url('../fonts/notosans-light-webfont.woff2') format('woff2'),
         url('../fonts/notosans-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}


@font-face {
    font-family: 'noto_sans';
    src: url('../fonts/notosans-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/notosans-lightitalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}


@font-face {
    font-family: 'noto_sans';
    src: url('../fonts/notosans-medium-webfont.woff2') format('woff2'),
         url('../fonts/notosans-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: 'noto_sans';
    src: url('../fonts/notosans-mediumitalic-webfont.woff2') format('woff2'),
         url('../fonts/notosans-mediumitalic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}


@font-face {
    font-family: 'noto_sans';
    src: url('../fonts/notosans-regular-webfont.woff2') format('woff2'),
         url('../fonts/notosans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'noto_sans';
    src: url('../fonts/notosans-semibold-webfont.woff2') format('woff2'),
         url('../fonts/notosans-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}


@font-face {
    font-family: 'noto_sans';
    src: url('../fonts/notosans-semibolditalic-webfont.woff2') format('woff2'),
         url('../fonts/notosans-semibolditalic-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;
}


@font-face {
    font-family: 'noto_sans';
    src: url('../fonts/notosans-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/notosans-extrabold-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}


@font-face {
    font-family: 'noto_sans';
    src: url('../fonts/notosans-extrabolditalic-webfont.woff2') format('woff2'),
         url('../fonts/notosans-extrabolditalic-webfont.woff') format('woff');
    font-weight: 800;
    font-style: italic;
}


//serif


@font-face {
    font-family: 'noto_serif';
    src: url('../fonts/notoserif-italic-webfont.woff2') format('woff2'),
         url('../fonts/notoserif-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}


@font-face {
    font-family: 'noto_serif';
    src: url('../fonts/notoserif-regular-webfont.woff2') format('woff2'),
         url('../fonts/notoserif-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'noto_serif';
    src: url('../fonts/notoserif-medium-webfont.woff2') format('woff2'),
         url('../fonts/notoserif-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: 'noto_serif';
    src: url('../fonts/notoserif-mediumitalic-webfont.woff2') format('woff2'),
         url('../fonts/notoserif-mediumitalic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}


@font-face {
    font-family: 'noto_serif';
    src: url('../fonts/notoserif-bold-webfont.woff2') format('woff2'),
         url('../fonts/notoserif-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}


@font-face {
    font-family: 'noto_serif';
    src: url('../fonts/notoserif-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/notoserif-bolditalic-webfont.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

// @font-face {
//     font-family: 'noto_sans_gu';
//     src: url('../fonts/NotoSansGujaratiUI-Regular.ttf') format('ttf');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'noto_sans_gu';
//     src: url('../fonts/NotoSansGujaratiUI-Bold.ttf') format('ttf');
//     font-weight: bold;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'noto_sans_gu';
//     src: url('../fonts/notosansgujaratiui-bold-webfont.woff2') format('woff2'),
//          url('../fonts/notosansgujaratiui-bold-webfont.woff') format('woff');
//     font-weight: bold;
//     font-style: normal;

// }

// @font-face {
//     font-family: 'noto_sans_gu';
//     src: url('../fonts/notosansgujaratiui-regular-webfont.woff2') format('woff2'),
//          url('../fonts/notosansgujaratiui-regular-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;

// }

@font-face {
    font-family: 'noto_sans_gu';
    src: url('../fonts/notosansgujaratiui-bold-webfont.woff2') format('woff2'),
         url('../fonts/notosansgujaratiui-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'noto_sans_gu';
    src: url('../fonts/notosansgujaratiui-regular-webfont.woff2') format('woff2'),
         url('../fonts/notosansgujaratiui-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}



@font-face {
    font-family: 'noto_sans_kn';
    src: url('../fonts/notosanskannadaui-bold-webfont.woff2') format('woff2'),
         url('../fonts/notosanskannadaui-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'noto_sans_kn';
    src: url('../fonts/notosanskannadaui-regular-webfont.woff2') format('woff2'),
         url('../fonts/notosanskannadaui-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'noto_sans_or';
    src: url('../fonts/notosansoriyaui-bold-webfont.woff2') format('woff2'),
         url('../fonts/notosansoriyaui-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'noto_sans_or';
    src: url('../fonts/notosansoriyaui-regular-webfont.woff2') format('woff2'),
         url('../fonts/notosansoriyaui-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'noto_sans_te';
    src: url('../fonts/notosansteluguui-bold-webfont.woff2') format('woff2'),
         url('../fonts/notosansteluguui-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}




@font-face {
    font-family: 'noto_sans_te';
    src: url('../fonts/notosansteluguui-regular-webfont.woff2') format('woff2'),
         url('../fonts/notosansteluguui-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


//noto sans devanagari

/* devanagari */
@font-face {
  font-family: 'noto_sans_devanagari';
  font-style: italic;
  font-weight: normal;
  font-display: swap;
  src: local('Noto Sans Italic'), local('NotoSans-Italic'), url(https://fonts.gstatic.com/s/notosans/v9/o-0OIpQlx3QUlC5A4PNr4ARDQ_mu72BiBLE.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* devanagari */
@font-face {
  font-family: 'noto_sans_devanagari';
  font-style: italic;
  font-weight: bold;
  font-display: swap;
  src: local('Noto Sans Bold Italic'), local('NotoSans-BoldItalic'), url(https://fonts.gstatic.com/s/notosans/v9/o-0TIpQlx3QUlC5A4PNr4Az5ZuyCzW1IPriezag.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* devanagari */
@font-face {
  font-family: 'noto_sans_devanagari';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: local('Noto Sans'), local('NotoSans'), url(https://fonts.gstatic.com/s/notosans/v9/o-0IIpQlx3QUlC5A4PNr5DRASf6M7VBj.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}

/* devanagari */
@font-face {
  font-family: 'noto_sans_devanagari';
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: local('Noto Sans Bold'), local('NotoSans-Bold'), url(https://fonts.gstatic.com/s/notosans/v9/o-0NIpQlx3QUlC5A4PNjXhFVZdyBx2pqPIif.woff2) format('woff2');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
