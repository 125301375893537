.kp-container-fluid__published-listing-filters{
  @include media768(){
    padding-top: 4rem;
    padding-bottom: 4rem;
    border-bottom: 1px solid $greyColor15;
  }
}

//generic
.ListingPage__listing-container{
  background-color: $none;

  @include media768(){
    // padding-top: 4rem;
  }

  .ListingPage__listing-row{

    .ListingPage__card{
      // padding: 3rem 1.5rem;
    }
  }
}


