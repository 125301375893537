.kp-internal-resource-select{
  .kp-internal-resource-select__header{
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
  }
  // position: relative;
  .kp-modal__btn-group{
    position: absolute;
    bottom: 0;
    width: 100%;
  }


}
