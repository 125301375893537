.kp-choose-content-type-container-fluid{
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 9rem;
  margin-top: -9rem;
  background: rgba(0,0,0,0);
  min-height: 100vh;
}

.kp-content-type-cards-select{
  height: 100%;

  @include media992(){
    margin-top: -4rem;
  }
}

.kp-content-type-card-container{

  .kp-content-type-card-container-2-wrapper{
    padding-top: 4rem;
    padding-bottom: 4rem;
    @include kp-separator-top();
  }

  &:first-of-type{
    .kp-content-type-card-container-2-wrapper{
      @include kp-no-separator();
    }
  }

  @include media992(){
    padding-top: 8rem;
    padding-bottom: 8rem;
    padding-left: 0;
    padding-right: 0;

    .kp-content-type-card-container-2-wrapper{
      padding: 0;
      @include kp-no-separator();
    }

    &:nth-child(2n){
      .kp-content-type-card-container-2-wrapper{
        @include kp-separator-left();
      }
    }
  }

  .kp-content-type-card-container-2{
    padding: 0;
  }
}

@include media768(){

  .kp-content-type-card-container{
    .kp-content-type-card-container-2{
      padding: 0 1rem;
    }
  }
}
