.statusTag-wrapper{
  
  
  display: flex; //
  
  .statusTag{
    @include weight-semibold();
    padding: 0 0.5rem;
    border-radius : 2px;
    color: $white;
  }
  
  &.success{
    .statusTag{
      background-color: $green;
    }
  }
  &.danger{
    .statusTag{
      background-color: $red;
    }
  }
  &.neutral{
    .statusTag{
      color: $greyColor40;
      background-color: $greyColor10;
    }
  }
}