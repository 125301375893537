.kp-profile--view, .kp-profile--edit{
  &.kp-profile--view--organisations, &.kp-profile--edit--organisations{

    .kp-tpl-block--head-office-location-display{
      padding-top: 0.5rem;
      display: flex;
      justify-content: center;
    }
    
  }
}
