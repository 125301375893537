

.TableElem__Header, .TableElem__Row{
    min-height: 4rem;
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 2px;

    .TableElem__HeaderCell, .TableElem__RowCell{
        flex: 1 0 0; // the 0 on basis makes sure that all of them grow equally
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.TableElem__Header{
    background-color: $greyColor3;
}

.TableElem__Row-Wrapper{
    overflow: hidden;
    @include kp-transition(height);
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}
.TableElem__Row{
    background-color: $greyColor5;
    border: 1px solid $greyColor5;
    

    &.-expandible-{
        @include kp-transition(background-color);
        cursor: pointer;

        &:hover{
            background-color: $white;
        }
    }
    
}

.TableElem__HeaderCell{

}

.TableElem__RowCell{

}