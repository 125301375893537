@mixin date-time-picker-styling(){
  background-color: $white;
  height: 4rem;
  border-radius: 1rem;
  padding: 0 2rem;
  color: $primaryColorText;
  @include h5();
  @include sans-serif();
  @include weight-semibold();
}

.kp-date-time-picker{
  margin: -1rem;
  display: flex;
  flex-wrap: wrap;

  .kp-picker-wrapper{
    display: flex;
    align-items: center;
    padding: 1rem;

    .kp-picker-icon{
      padding-right: 1rem;
    }
    //react-date-picker overrides
    .react-date-picker{
      @include date-time-picker-styling();

      .react-date-picker__calendar{
        z-index: 10;
      }

      .react-date-picker__wrapper{
        @include no-border();

        input{
          color: $primaryColorText;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }

        .react-date-picker__clear-button__icon{
          stroke: $primaryColor100;
        }
      }

      &.react-date-picker--disabled{
        background-color: $greyColor10;
        color: $greyColor70 ;

        .react-date-picker__wrapper{


          input{
            color: $greyColor70;

          }

          .react-date-picker__clear-button__icon{
            stroke: $greyColor70;
          }
        }
      }
    }

    .react-daterange-picker{
      @include date-time-picker-styling();

      .react-daterange-picker__wrapper{
        border: 0;

        input{
          color: $primaryColorText;
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
      }

      .react-daterange-picker__clear-button{
        >svg{
          stroke: $primaryColor100;
        }
      }
    }

    .react-timerange-picker{


      .react-timerange-picker__wrapper{
        @include no-border();

        .react-timerange-picker__inputGroup{
          @include date-time-picker-styling();
          padding: 0 1rem;
          @include mediaQuery('sm'){
            padding: 0 2rem;
          }

          &:first-of-type{
            @include mediaQuery('sm'){
              margin-right: 1rem;
            }
          }

          &:last-of-type{
            @include mediaQuery('sm'){
              margin-left: 1rem;
            }
          }

          input{
            color: $primaryColorText;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
          }

          select{
            color: $primaryColorText;
          }

          .react-timerange-picker__inputGroup__amPm{
            appearance: unset;
            -moz-appearance: unset;
          }
        }

        .react-timerange-picker__range-divider{
          @include h5();
          @include sans-serif();
          // @include weight-semibold();
          color: $greyColor70;

        }

        .react-timerange-picker__clear-button__icon{
          stroke: $primaryColor100;
        }
      }
    }
  }
}
