.kp-dd__options-wrapper{
  position: absolute;
  left: 0;
  z-index: 10000000;
  cursor: pointer;
  width: 100%;


  .kp-dd__options{
    background-color: $white;
    list-style: none;
    padding-left: 0;
    // padding-top: 1rem;
    // padding-bottom: 1rem;
    max-height: 20rem;
    overflow: scroll;
    @include box-shadow-3();
    border: 1px solid $greyColor10;
    border-top: none;
    // background-color: $greyColor5;

    .kp-dd__option{
      padding: 1rem 2rem;

      &:hover{ background-color: $primaryColor10 }

      &.selected{ background-color: $primaryColor10 }

      &.disabled{
        cursor: not-allowed;
        color: $greyColor70;

        .kp-dd__option-count{ color: $greyColor15 }
      }

      .kp-dd__option-count{
        padding-left: 0.5rem;
        color: $greyColor70;
      }
    }
  }
}

//comp & child comp overrides
.kp-dd__options-wrapper{
  .kp-dd__options--multi{

    .kp-dd__option{

      .kp-checkbox-input__option{
        padding-bottom: 0;
      }

      &.selected{
        background-color:$none;

        .kp-checkbox-input__option-text{
          color: $primaryColorText;
          @include weight-bold();
        }
      }
    }
  }
}
