.kp-admin-newsletter-edit{

  .kp-admin-newsletter-edit__list{
    background-color: $none;
    min-height: calc(100vh - 6rem);
  }

  .kp-admin-newsletter-edit__publish-newsletter-btn-wrapper{
      position: sticky;
      bottom: 0;
      padding: 1rem 0;
      background-color: $white;
      border-top: 1px solid $greyColor15;
      z-index: 10;
      display: flex;
      justify-content: center;

      .kp-btn-wrapper{
        margin: 0 0.5rem;
        &:first-of-type{ margin-left: 0 }
        &:last-of-type{ margin-right: 0 }
      }
  }
}
