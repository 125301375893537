.kp-link-input-view--internal{

  .kp-link-input-view__option{
    cursor : pointer;
    border-bottom: 1px solid $greyColor15;
    padding: 0.5rem;
    background-color: $white;

    &.active{
      background-color: $primaryColor10;
    }
  }
}
