//#1 card--story dimensions config
$cardHeight: 46rem;

//#1 card--tools dimensions config
$toolsCardImgMarginTop: 4rem;
$toolsCardHeight: 35rem;
$toolsCardImgHeight: 8rem;
$toolsCardImgWidth: 8rem;
$toolsCardTextAreaHeight: $toolsCardHeight - $toolsCardImgHeight - $toolsCardImgMarginTop;

//#1 card--toolkits dimensions config
$toolkitsCardPadding: 4rem;
$toolkitsCardHeight: 38rem;
$toolkitsCardTextGroupHeight: 18rem;
$toolkitsCardMetaGroupHeight: $toolkitsCardHeight - $toolkitsCardTextGroupHeight - ($toolkitsCardPadding*2);
$toolkitsCardMinWidth: 43rem;

$cardMinWidth: 32rem;


.kp-content-card-wrapper{

  padding: 1rem;
  min-width: $cardMinWidth;


  .kp-content-card-wrapper__remove-btn{
    position: absolute;
    right: 1rem;
    top: 1rem;
    transform: translate(50%,-50%);
    z-index: 10;
  }


  .kp-content-card{
    position: relative;
    // background-color: $white;
    // border: 0.5px solid $greyColor15;
    // border-radius: 1rem;
    // @include box-shadow-0();
    overflow: hidden;
    cursor: pointer;
    @include kp-transition(all);

    &:hover{
      @include box-shadow-2();
      transform: scale(1.015);
    }

    @include media576(){
      // height: $cardHeight;
    }
  }

  .kp-content-card__bottom-tags{
    &.kp-content-card__tag-wrapper{
      display: flex;
      flex-wrap: wrap;
      margin-top: -0.5rem;
      margin-left: -0.5rem;
      padding: 0;

      .kp-content-card__tag{
        background-color: $primaryColor10;
        color: $primaryColorText;
        // @include u-case();
        // @include ls-10();
        font-size: 12px;
        @include weight-medium();
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: 0.5rem;
        margin-left: 0.5rem;
        margin-top: 1rem;
      }
    }
  }

    .kp-content-card__content{
      height: 100%;
      position: relative;
      

      .kp-content-card__inline-alert{
        padding-top: 0;
        padding-bottom: 1rem;
        margin-top: -1rem;
        margin-left: -1rem;
      }
      
      .kp-content-card__text-wrapper{
        padding: 2rem;

        @include media576(){
          padding-bottom: 3rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .kp-content-card__meta-primary{
          padding-bottom: 1.5rem;
        }

        .kp-content-card__title{
          @include titleSerif--S();
          // @include kp-clamp-text(2);
        }

        .kp-content-card__summary{
          padding-top: 1rem;
          // @include kp-clamp-text(2);
        }

        .kp-content-card__meta-secondary{
          padding-top: 0.5rem;
          // color : $greyColor70;
        }

        .kp-content-card__meta-group{
          //content card actions inside meta-group is created wheen the sub-prop displayStyle = inline is passed to the resourceLink prop . for this sort of inline action. we want no padding.
          .kp-content-card__actions{
            padding: 0 !important;
          }
        }
      }
    }

    .kp-content-card__actions{
      display: flex;
      margin-left: 1rem;
      margin-right: 1rem;

      .kp-content-card__action-icon-btn{
        margin-left: 1rem;
        margin-right: 1rem;
        height: 4rem;
        width: 4rem;
      }

      .kp-content-card__action-text-btn{
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }

}








//targetted styling for different card 'types'

  .kp-content-card-wrapper{

    //2
    &.published--tools{
      min-width: $cardMinWidth;

      .kp-content-card{
        background-color: $white;
        border: 1px solid $greyColor15;




        @include media768(){
          height: $toolsCardHeight;

          .kp-content-card__text-wrapper{
            height: 100%;

          }
        }

        .kp-content-card__icon-container{
          margin: $toolsCardImgMarginTop 2rem 0 2rem;
          width: $toolsCardImgWidth;
          height: $toolsCardImgHeight;
          // background-color: $white;

          .kp-content-card__icon{
            height: 100%;
            width: auto;
          }
        }

        .kp-content-card__text-wrapper{
          padding: 2rem;

          .kp-content-card__summary{
            -webkit-line-clamp: 3;
          }
        }
      }
    }

}

.kp-content-card-wrapper{

    //2
    &.published--toolkits{
      min-width: $cardMinWidth;


      @include media576(){
        min-width: $toolkitsCardMinWidth;
      }


      .kp-content-card{
        background-color: $primaryColor10;
        padding: $toolkitsCardPadding;

        @include media768(){
          height: $toolkitsCardHeight;

          .kp-content-card__meta-group{
            height: $toolkitsCardMetaGroupHeight;
          }

          .kp-content-card__text-group{
            height: $toolkitsCardTextGroupHeight;
          }
        }

        .kp-content-card__text-group{
          padding-bottom: 3rem;

          .kp-content-card__title{
            // @include h2-serif();
            @include titleSerif--S();
            // @include weight-bold();
            -webkit-line-clamp: 3;
          }
        }



        .kp-content-card__text-wrapper{
          padding: 0;

          .kp-content-card__summary{
            -webkit-line-clamp: 3;
          }
        }
      }
    }

}

.kp-content-card-wrapper{

    //3
    &.draft--generic{

      .kp-content-card{
        display: flex;
        align-items: center;
        height: auto;

        .kp-content-card__content{
          flex: 1;
        }
      }
    }

}



//card style mixins
@mixin resourceTypeCardActions($color, $svgPathStroke){
  .kp-content-card__actions{
    padding: 2rem;
    margin: 0;
    align-items: center;
    justify-content: space-between;

    .kp-icon-btn{
      &::before{
        background-color: $color;
        border-radius: 0.4rem;
      }
      &::after{

        border-radius: 0.4rem;
      }

      svg path {
        stroke : $svgPathStroke;
      }
    }
  }
}
