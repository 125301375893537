
.OKE-404{
    text-align: center;
    height: 100%; 
    display: flex; 
    flex-direction: column; 
    justify-content: center; 
    align-items: center;
    position: fixed;
    top:6rem;
    padding-bottom:6rem;
    left: 0;
    z-index: 100000000000000000000;
}