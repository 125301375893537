$listExpandedCardHeight: 30rem;

.kp-content-card-wrapper{
  &.list-expanded--initiatives{
    min-width: auto;

    .kp-content-card{
      height: auto;
      display: flex; //this flex stuff is importatnt to make the card actions show up to the right of the content.
      flex-direction: row;
      align-items: center;
      padding: 2rem;

      .kp-content-card__content{
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        flex-wrap: wrap;
        margin: -1rem;


        .kp-content-card__text-wrapper{
          padding: 1rem;
          display: flex;
          height: auto;
          flex-direction: column;
          justify-content: space-between;
          flex-grow: 1;
          min-width: 40rem;

          .kp-meta-primary{
            padding-bottom: 0.5rem;
          }

          .kp-content-card__title{
            @include titleSerif--S();
            // @include h4-plus();
            // @include weight-bold();
            // @include sans-serif();
            padding: 0;
            padding-bottom: 0.2rem;
            @include kp-clamp-text(1);
          }

          .kp-content-card__summary-block{
            padding-top: 2rem;

            &:first-of-type{
              padding-top: 0
            }

            &.primary{
              .kp-content-card__summary{
                @include weight-semibold();
              }
            }
          }

          .kp-content-card__summary{
            @include h5();
            @include kp-clamp-text(3);
            padding: 0;
          }

          .kp-content-card__summary-label-wrapper{
            // padding-top: 2rem;
            padding-bottom: 0.5rem;
          }

          .kp-content-card__meta-group{
            .kp-meta-block-contents-wrapper{
              padding-top: 1rem;

              .kp-profile-img-display{
                display: none;
              }
            }
          }
        }



        .kp-content-card__img-container, .kp-video-embed{
          overflow: hidden;
          margin: 1rem;
          display: flex;
          justify-content: center;
          border-radius: 0.8rem;
          flex: 0 0 23rem;
          height: 23rem;
        }

        .kp-content-card__img-container{
          .kp-image-input{
            min-width: 37rem;
          }
        }

        .kp-meta-hero-wrapper{
          flex: 0 0 12rem;
          margin: 1.5rem;
          margin-right: 0;
          border-radius: 0.8rem;
          height: 12rem;
        }
      }
    }
    // .kp-content-card{
    //   border: none;
    //   border-radius: 0rem;
    //   flex-direction: row;
    //   border: 0.5px solid $greyColor15;
    //   border-radius: 1rem;
    //   background-color: $none;
    //   @include no-box-shadow();
    //
    //   &:hover{
    //     @include box-shadow-2();
    //   }

    //
    //   @include media768(){
    //     height: $searchCardHeight;
    //   }
    //
    //   .kp-content-card__content{
    //     flex: 1 0;
    //     flex-direction: row;
    //     display: flex;
    //
    //     .kp-content-card__img-container{
    //       flex: 0 0 19rem;
    //       padding: 2rem;
    //       padding-right: 0;
    //
    //       .kp-image-block{
    //         @include shape-soft-square();
    //       }
    //     }
    //     .kp-content-card__text-wrapper{
    //       flex: 1 1 auto;
    //       min-width: 0;
    //       justify-content: space-between;
    //       padding-bottom: 2rem;
    //
    //       .kp-content-card__text-group{
    //         .kp-content-card__title{
    //           @include h5-serif();
    //           @include weight-normal();
    //           padding-top: 0.5rem;
    //         }
    //       }
    //
    //       .kp-meta-block-contents-wrapper{
    //         padding-top: 1rem;
    //       }
    //     }
    //
    //   }
    //
    // }
  }
}

//stories, activities
.kp-content-card-wrapper{
  &.list-card--long-stories, &.list-card--long-videoStories, &.list-card--long-activities{
    .kp-content-card__content{
      .kp-content-card__img-container{
        .kp-image-input{
          min-width: 20rem;
        }
      }

      .kp-video-embed{
        .kp-video-embed__display{
          min-width: 20rem;
        }
      }
    }
  }
}

//resources, initatives
.kp-content-card-wrapper{
  &.list-card--long-resources, &.list-card--long-initiatives{
    .kp-content-card__content{
      .kp-content-card__img-container{
        .kp-image-input{
          min-width: 20rem;
        }
      }
    }
  }
}

//news
.kp-content-card-wrapper{
  &.list-card--long-news{
    .kp-content-card{
      .kp-content-card__content{

        .kp-content-card__text-wrapper{

          .kp-content-card__meta-primary{
            .kp-meta-primary__text--value{
              color: $greyColor70;
              letter-spacing: 0;
              @include weight-normal();
              text-transform: lowercase;

            }
          }

          .kp-content-card__title{
              @include kp-clamp-text(2);
          }

          .kp-content-card__meta-group{
              padding-top: 1rem;
          }
        }


        .kp-content-card__img-container{
          .kp-image-input{
            min-width: 12rem;
          }

          @include media768(){
            padding: 0;
          }
        }
      }
    }
  }
}


//events
.kp-content-card-wrapper{
  &.list-card--long-events{
    .kp-content-card{
      .kp-content-card__content{
        .kp-content-card__text-wrapper{
          .kp-content-card__title{
              @include kp-clamp-text(2);
          }
        }
      }
    }
  }
}

// //resources
// .kp-content-card-wrapper{
//   &.SEARCH_CARD{
//     min-width: $cardWidth;
//     &.published--resources, &.published--initiatives {
//
//       .kp-content-card .kp-content-card__content .kp-content-card__text-wrapper .kp-content-card__title{
//         @include kp-clamp-text(2);
//       }
//
//       .kp-content-card__actions-wrapper{
//         display: flex;
//         align-items: flex-end;
//
//         .kp-content-card__actions{
//           // margin-right: 3rem;
//           padding: 2rem;
//           padding-right: 3rem;
//           .kp-link{
//             display: none;
//           }
//         }
//       }
//
//     }
//   }
// }
//
//
// //event
// .kp-content-card-wrapper{
//   &.SEARCH_CARD{
//     min-width: $cardWidth;
//     &.published--events {
//
//       .kp-content-card {
//         background-color: $none;
//
//         @include media768(){
//           height: $searchCardHeight;
//         }
//
//       .kp-content-card__content {
//         .kp-meta-hero-wrapper{
//           margin: 2rem;
//           margin-right: 0;
//           width: 5rem;
//
//           .kp-meta-hero{
//             margin: 0;
//             padding: 0;
//           }
//         }
//
//         .kp-content-card__text-wrapper .kp-content-card__title{
//           @include kp-clamp-text(3);
//         }
//       }
//       }
//
//       .kp-content-card__actions-wrapper{
//         display: flex;
//         align-items: flex-end;
//
//         .kp-content-card__actions{
//           // margin-right: 3rem;
//           padding: 2rem;
//           padding-right: 3rem;
//           .kp-link{
//             display: none;
//           }
//         }
//       }
//
//     }
//   }
// }
//
// //news
// .kp-content-card-wrapper{
// &.SEARCH_CARD{
// min-width: $cardWidth;
// &.published--news{
//
//     .kp-content-card {
//       height: $searchCardHeight;
//
//       .kp-content-card__content {
//         .kp-content-card__text-wrapper {
//           padding: 2rem;
//
//           .kp-content-card__title{
//             @include weight-bold();
//             @include kp-clamp-text(2)
//           }
//           .kp-content-card__summary{
//             @include h5();
//             @include kp-clamp-text(3)
//             padding: 0;
//             padding-top: 0.5rem;
//           }
//         }
//       }
//     }
// }
// }
// }
