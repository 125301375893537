$sidebarEventsCardMinWidth: 15rem;
$sidebarEventsCardHeight: auto;


.kp-content-card-wrapper.sidebar--events{
    min-width: $sidebarEventsCardMinWidth;
    padding: 0;

    .kp-content-card{
      border-radius: 0;
      border: 0;
      background-color: $none;
      // border-color: black;
      border-bottom: 1px solid $greyColor15;
      @include no-box-shadow();

      &:hover{
        @include box-shadow-2();
      }

      @include media576(){
        height: $sidebarEventsCardHeight;
      }

      .kp-content-card__content{
        // display: flex;
        align-items: center;

        .kp-meta-hero-wrapper{
          flex-basis: 8rem;
          flex-shrink: 0;

          .kp-meta-hero{
            // padding: 2rem;
            // padding-bottom: 0;
            // padding-right: 0;
            padding: 0;
            background-color: $none;
          }
        }

        .kp-content-card__text-wrapper{
          // height: 100%;
          padding: 2rem;
          padding-bottom: 2.5rem;

          .kp-content-card__meta-primary{
            padding-bottom: 1rem;
          }

          .kp-content-card__title{
            // padding-top: 1rem;
            // @include kp-clamp-text(3);
            @include bodySans--M();
            // @include weight-semibold();
          }

          .kp-meta-block-contents-wrapper{
            padding-top: 1.5rem;

            .kp-meta-block__meta-wrapper{
              max-width: 100%;
            }
          }

        }
      }


    }
}
