
.PageCover{
  background-color: $greyColor100;
  height: 30rem;

  .PageCover-container{
    height: 100%;
    text-align: center;
  
    .PageCover__content{
      max-width: 60rem;
      color: $white;
      text-align: center;
      margin: 0 auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .PageCover__subtitle{
        padding-top: 1rem;
      }

      .PageCover__cta{
        margin-top: 2rem;  
      }
    }
  }
}