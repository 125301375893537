.kp-register-form {
  border-top: 1px solid $greyColor15;
  padding-top: 3rem;
}

.kp-register-form-wrapper {
  position: relative;

  h2 {
    display: none;

    @include media992() {
      display: block;
    }
  }

  .kp-register-form__card--left {
    @include media992() {
      width: 50%;
      float: left;
      padding-right: 2rem;
    }

  }

  .kp-register-form__card--right {
    @include media992() {
      width: 50%;
      border-left: 1px solid $greyColor15;
      float: left;
      transition: 0.3s;
      padding: 7.2rem 2rem 5rem 2rem;
    }
  }
}

.kp-password-validation-rule-wrapper--shape {
  width: 25rem;
  height: 15rem;
  background: #ffffff;
  // border-radius: 1rem;
  position: absolute;

  right: -2rem;
  top: 10rem;
  padding: 3rem 0rem 3rem 2rem;
  border-radius: .5rem;
  z-index: 2222;
  @include box-shadow-3();

  .kp-password-rule {
    font-size: 1.4rem;
  }

  @include media992() {
    right: -2rem;
    top: -6rem;
  }
}