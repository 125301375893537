
//overriding .pageContainerFluid's padding-right, 
//because this whole container is wrapper in another PageContainer : Profile__page-container
.RelatedContent-Container{
  padding-right: 0;
  padding-left: 0;

  @include mediaQuery('lg'){
    @include pageContainerFluid();
    padding-right: 0;
  }
  
}

//what is this??
@include media768(){
    .kp-profile-related-content{
      min-height: calc(100vh - #{$secondaryHeaderHeight_D})
    }
}
