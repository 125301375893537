$storyCardHeight: 46rem;

.kp-content-card-wrapper{

  //1
  &.published--stories, &.published--activities, &.published--videoStories, &.published--initiatives{

    .kp-content-card{

      @include media768(){
        // height: $storyCardHeight;

        .kp-content-card__content{
          display: flex;
          flex-direction: column;

          .kp-content-card__text-wrapper{
            flex-grow: 1;
          }
        }
      }

      .kp-content-card__img-container{
        // height: $storyCardImgHeight;
        .kp-image-block-wrapper{
          height: 0;
          padding-bottom: 62.804%;
        }
      }
    }
  }

}
