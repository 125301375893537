.kp-radio-input{
  display: flex;
  flex-wrap: wrap;

  .kp-radio-input__option{
    display: flex;
    padding-right: 2rem;
    padding-bottom: 1rem;
    cursor: pointer;

    &:last-of-type{
      padding-right: 0;
    }

    &:hover .radioInputElement:not(.selected){
      background-color: $primaryColor10;
    }

    
  }
}
