$adminSidebarWidth: 25rem;

.kp-admin-dashboard{
  // background-color: aqua;

  .kp-admin-db-sidebar-wrapper{
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    width: $adminSidebarWidth;

    .kp-admin-db-sidebar{
      height: 100%;
      // background-color: blue;
      width: $adminSidebarWidth;
      padding: 4rem 0;

      .kp-admin-db-sidebar__option{
        padding: 1rem 4rem;
        @include kp-transition(background-color);
        cursor: pointer;

        &:hover{
          background-color: $primaryColor10;
        }

        .kp-admin-db-sidebar__option-text{
          &.active{
            @include weight-bold();
            color: $primaryColorText;
          }
        }
      }
    }
  }


  .kp-admin-db-body-wrapper{
    margin-left: $adminSidebarWidth;
    margin-top: -100vh;

    .kp-admin-db-body{
      min-height: 100vh;
      background-color: $greyColor5;
    }
  }

}
