.TableExpandComp{
    .TableElem-Wrapper{
        overflow-x: unset;
        .TableElem__Header{
            background-color: $none;
            border: none;
            border-bottom: 1px solid $greyColor10;
            color: $greyColor40 !important;
        }
        .TableElem__Row{
            background-color: $none;
            border: none;
            border-bottom: 1px solid $greyColor5;
        }
    }
}