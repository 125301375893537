$black: #000;
$black-opacity80: rgba(0, 0, 0, 0.8);
$black-opacity03: rgba(0, 0, 0, 0.03);
$black-opacity05: rgba(0, 0, 0, 0.05);
$black-opacity50: rgba(0, 0, 0, 0.5);


$white: #fff;
$white-opacity50: rgba(255, 255, 255, 0.5);
$white-opacity80: rgba(255, 255, 255, 0.8);
$none: rgba(0, 0, 0, 0);


$primary100SL: 89%,
95%;
$light-secondary: #F4F0ED; // here because hover color is not decided yet


$primaryColor100: #069325;
$primaryColor40: #9BD4A8;
$primaryColor10: #E6F4E9;

$primaryColorText:#069325;

$greyColor100: #383838;
$greyColor80: #606060;
$greyColor70: #747474;
$greyColor40: #afafaf;
$greyColor15: #e1e1e1;
$greyColor10: #EBEBEB;
$greyColor5: #f5f5f5;
$greyColor3: #f9f9f9;

$lightShadowColor: #069325;
$darkShadowColor:  $white;
$disabledShadowColor:  $greyColor70;

$red : #E60000;
$light-red : #ffb5bf;
$xLightRed : #ffe7ea;
$yellow : #FFCC00;
$light-yellow : #fff0b5;
$green : #339900;
$light-green : #b5ffc8;

$primaryHeaderHeight_D: 10rem;
$primaryHeaderHeight_M: 10rem;

$overlayPgHeaderHeight_D: 6rem;
$overlayPgHeaderHeight_M: 6rem;

$secondaryHeaderHeight_D: 4rem;

$adminSidebarWidth: 25rem;

$toolbarHeight: 5rem;

$pageMargin_D: 6rem;
$pageMargin_M: 2rem;

$gutter: 2rem;
$halfGutter: $gutter / 2;

$adminTableRowPadding : 4rem;

//home card related

$homeHorSection__ResourcesCardHeight : 14rem;
$homeHorSection__ToolsCardHeight : 15rem;
$homeHorSection__NewsCardHeight: 31rem;

@mixin custom-btn-types() {
  &.custom-type--events {
    background-color: $none;

    &::before {
      // background-color: $primaryColor100
      
    }
  }

  &.custom-type--stories {
    background-color: $none;

    &::before {
      // background-color: $primaryColor100
      
    }
  }

  &.custom-type--resources {
    background-color: $none;

    &::before {
      // background-color: $primaryColor100
      
    }
  }


  &.custom-type--initiatives {
    background-color: $none;

    &::before {
      // background-color: $primaryColor100
      
    }
  }

  &.custom-type--innovations {
    background-color: $none;

    &::before {
      // background-color: $primaryColor100
      
    }
  }

  &.custom-type--news {
    background-color: $none;

    &::before {
      // background-color: $primaryColor100
      
    }
  }
}

@mixin  svgColorSet($color) {
  rect, line, polygon, circle, polyline, path, ellipse{
    stroke: $color;
    @include kp-transition(stroke)
  }
}

@mixin box-shadow-0() {
  -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.05);
}

@mixin inner-shadow-0() {
  -webkit-box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.05);
}

@mixin box-shadow-1() {
  -webkit-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
}

@mixin box-shadow-1-invert() {
  -webkit-box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.05);
  box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.05);
}

@mixin box-shadow-2() {
  -webkit-box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.1);
}

@mixin box-shadow($x_y, $blur, $opacity) {
  -webkit-box-shadow: $x_y $x_y $blur rgba(0, 0, 0, $opacity);
  -moz-box-shadow: $x_y $x_y $blur rgba(0, 0, 0, $opacity);
  box-shadow: $x_y $x_y $blur rgba(0, 0, 0, $opacity);
}

@mixin box-shadow-3() {
  -webkit-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
}

@mixin box-shadow-4() {
  -webkit-box-shadow: 0px 0px 150px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 150px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 150px rgba(0, 0, 0, 0.1);
}

@mixin no-box-shadow() {
  -webkit-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  -moz-box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}

@mixin inner-shadow-1() {
  -webkit-box-shadow: inset 0 -2rem 1.5rem -10px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 -2rem 1.5rem -10px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 -2rem 1.5rem -10px rgba(0, 0, 0, 0.05);
}

@mixin kp-dashed-border() {
  border: 2px solid $greyColor15;
}

@mixin kp-separator-bottom() {
  border-bottom: 2px solid $greyColor15;
}

@mixin kp-separator-top() {
  border-top: 2px solid $greyColor15;
}

@mixin kp-separator-left() {
  border-left: 2px solid $greyColor15;
}

@mixin kp-separator-right() {
  border-right: 2px solid $greyColor15;
}

@mixin kp-no-separator() {
  border: 0;
}

@mixin kp-transition($property) {
  transition: $property 0.3s ease-out;
}

@mixin kp-multi-transition($properties...) {
  $transitionString: ' ';

  @each $property in $properties {
    $transitionString: unquote($transitionString + $property + ' 0.3s ease-out, '),
  }

  transition: $transitionString;
}

@mixin kp-transition-2($property, $duration) {
  transition: $property $duration ease-out;
}

@mixin kp-transition-with-delay($property, $delay) {
  transition: $property 0.3s ease-out;
  transition-delay: $delay;
}

@mixin underlineForAnimation($thickness, $animDuration){
  background-size: 0 100%;
  background-image: linear-gradient(180deg,transparent percentage((100-$thickness)/100),$primaryColor100 0);
  background-repeat: no-repeat;
  @include kp-transition-2(background-size, $animDuration);
}

@mixin kp-clamp-text($noOfLines) {
  display: -webkit-box;
  -webkit-line-clamp: $noOfLines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin kp-ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin kp-text-input-hover() {
  &::after {
    content: '';
    height: 100%;
    // border-radius: 1rem;
    width: 0;
    background-color: $primaryColor10;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    @include kp-transition(width);
  }

  &:hover {
    &::after {
      width: 100%
    }
  }
}


@mixin kp-remove-ul-styling() {
  list-style: none;
  padding-left: 0;
}

//media queries
@mixin media576() {

  //sm
  @media (min-width: 576px) {
    @content;
  }
}

@mixin media768() {

  //md
  @media (min-width: 768px) {
    @content;
  }
}

@mixin media992() {

  //lg
  @media (min-width: 992px) {
    @content;
  }
}

@mixin media1200() {

  //xl
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin no-border() {
  border: 0;
}

@mixin noTextSelect() {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin positionSticky() {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
}

//important to make image block or contents of an image input compo to work correctly
@mixin imageBlockPos() {
  position: relative;
  overflow: hidden;
}

@mixin imageBlockContentPos() {
  position: absolute;
  top: 0;
  left: 0;
}

@mixin kp-body--std-width() {
  @include kp-offset-container();

  // @include make-col-ready();
  @include media-breakpoint-up(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }

  @include media-breakpoint-up(lg) {
    @include make-col(8);
    @include make-col-offset(2);
  }
}


//color classes

.color-red {
  color: $red;
}

.color-green {
  color: $green;
}

.shape-square {
  border-radius: 0;
}

.shape-soft-square {
  border-radius: 0.5rem;
}

.shape-soft-square-1 {
  border-radius: 1rem;
}

.shape-soft-square-2 {
  border-radius: 2rem;
}

.shape-soft-square-4 {
  border-radius: 4rem;
}

@mixin shape-soft-square() {
  border-radius: 0.5rem;
}

.shape-round {
  border-radius: 50%;
}

@mixin clip-dy-res-text() {
  .kp-dy-selected-comp__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .kp-link-wrapper {
    padding-right: 2.5rem !important;
    width: 100%;

    .kp-link {
      width: 100%;

      .kp-link-content {
        width: 100%;

        .kp-link-content__text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

@mixin kp-dy-meta-elem-width() {

  &:nth-last-child(4):first-child,
  &:nth-last-child(4):first-child~* {
    width: 25%;
  }

  &:nth-last-child(3):first-child,
  &:nth-last-child(3):first-child~* {
    width: 33.333%;
  }

  &:nth-last-child(2):first-child,
  &:nth-last-child(2):first-child~* {
    width: 50%;
  }

  &:nth-last-child(1):first-child,
  &:nth-last-child(1):first-child~* {
    width: 100%;
  }
}

@mixin kp-events-meta-hero-styling() {
  .kp-meta-hero-wrapper {
    // width: 12rem;
    // height: 12rem;
    // margin-left: 2rem;
    // margin-top: 2rem;
    // flex: 1 0 auto;


    .kp-meta-hero {
      // background-color: $primaryColor10;
      // width: 100%;
      // height: 100%;
      // padding: 1.5rem;
      padding-left: 0;
      border-radius: 1rem;

      >.h3 {
        line-height: 1.2em
      }

      >.h4 {
        line-height: 2em
      }
    }
  }
}

@mixin skeletonLoaderAnimation(){
  overflow: hidden;
  position: relative;
  
  &::before{
    content: '';
    display: block;
    position: absolute;
    left: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent 0%, $black-opacity03 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0.0, 0.2, 1);
    animation-iteration-count:infinite;
  }

  @keyframes load {
    from { left: -100%; }
    to   { left: 100%; }
  }
}

@mixin headerLinkHover--secondary(){
  background-color: $none;
  @include kp-transition(background-color);
  &:hover{ background-color: $greyColor5 }
}

@mixin headerIconButton(){
  border: none;
  background-color: $white;
  padding-left: 2rem;
  padding-right: 2rem;
  height: 100%;
  @include headerLinkHover--secondary();
}

@mixin inputElements__hoverAndFocus(){
  @include kp-transition(background-color);
  
  &::after{
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: $primaryColor100;
    @include kp-transition(width);
  }

  
  &:hover{ background-color: $greyColor10 }

  
  &.focussed{
      background-color: $greyColor10;
      &::after{
          width: 100%;
      }
  }
}


:export {
  primaryColor100: $primaryColor100;
  primaryColor40: $primaryColor40;
  primaryColor10: $primaryColor10;
  
  primaryColorText: $primaryColorText;

  greyColor100: $greyColor100;
  greyColor80: $greyColor80;
  greyColor70: $greyColor70;
  greyColor40: $greyColor40;
  greyColor15: $greyColor15;
  greyColor10: $greyColor10;
  greyColor5: $greyColor5;
  greyColor3: $greyColor3;

  black: $black;
  white: $white;
  none: $none;

  primary100SL: $primary100SL;


  red: $red;
  lightRed: $light-red;
  yellow: $yellow;
  lightYellow: $light-yellow;
  green: $green;
  lightGreen: $light-green;

  secondaryHeaderHeight_D: $secondaryHeaderHeight_D;

  homeHorSection__ResourcesCardHeight: $homeHorSection__ResourcesCardHeight;
  homeHorSection__ToolsCardHeight: $homeHorSection__ToolsCardHeight;
  homeHorSection__NewsCardHeight: $homeHorSection__NewsCardHeight;

  overlayPgHeaderHeight_D: $overlayPgHeaderHeight_D;

  toolbarHeight: $toolbarHeight;

}