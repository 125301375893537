$profileCardMinWidth: 32rem;

.kp-profile-card-link-wrapper{
  cursor: pointer;
}


//cannot next kp-profile-card inside the above because the link may not be rendered everything this comp is used. e.g. profile landing.
.kp-profile-card{
  min-width: $profileCardMinWidth;
  position: relative;
  background-color: $white;
  @include box-shadow-1();
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;

  @include media768(){
    height: 35.5rem;
  }

  .kp-profile-card__img-plus-text-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;

    .kp-profile-card__img-container{
      width: 10rem;
      height: 10rem;
      border-radius: 10rem;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;

    }

    .kp-profile-card__text-wrapper{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 2rem;

      .kp-profile-card__title{
        text-align: center;
      }

      .kp-profile-card__subtitle{
        padding-top: 0.5rem;
      }

      .kp-profile-card__desc{
        padding-top: 2rem;
        text-align: center;

        @include kp-clamp-text(4)
      }

      .kp-profile-card__meta-block{
        width: 100%;
      }
    }
  }
}

//overrides

.kp-profile-card__meta-block .kp-meta-block__meta-wrapper{
  max-width: 100%;

  .kp-meta-block__meta-text{
    justify-content: center;
  }
}
