.TableElem-Wrapper{
    overflow-x: scroll;
    
    // @include mediaQuery('md'){
    //     overflow-x: unset;
    // }
    

    .TableElem{
        min-width: map-get($breakpoints, 'md');    
    }
}