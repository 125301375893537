@import '~modularscale-sass/stylesheets/modularscale';

$modularscale: (
  base: 16px 14px,
  ratio: 1.250
);

//older stuff below

//TYPESHEET
$pSerifFontsize_D: 14px;
$pSerifFontsize_M: 12px;

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6, .p,
p, ul, li, a, input, label, button {
  font-family: 'noto_sans', 'noto_sans_devanagari', 'noto_sans_gu', 'noto_sans_te', 'noto_sans_kn', 'noto_sans_or',  sans-serif ;
  font-weight: normal;
  font-style: normal;
  margin: 0;
}


.serif{
  font-family: 'noto_serif', 'noto_sans_devanagari' , 'noto_sans_gu', 'noto_sans_te', 'noto_sans_kn', 'noto_sans_or',  sans-serif ;
}

@mixin serif(){
  font-family: 'noto_serif', 'noto_sans_devanagari' , 'noto_sans_gu', 'noto_sans_te', 'noto_sans_kn', 'noto_sans_or',  sans-serif ;
}

.sans-serif{
  font-family: 'noto_sans', 'noto_sans_devanagari', 'noto_sans_gu', 'noto_sans_te', 'noto_sans_kn', 'noto_sans_or',  sans-serif ;
}

@mixin sans-serif(){
  font-family: 'noto_sans', 'noto_sans_devanagari', 'noto_sans_gu', 'noto_sans_te', 'noto_sans_kn', 'noto_sans_or',  sans-serif ;
}

//font style classes
@mixin italic(){
  font-style: italic;
}

.italic{
  @include italic();
}


//font weight classes

.light{
  font-weight: 300;
}

@mixin weight-light(){
  font-weight: 300;
}

@mixin weight-normal(){
  font-weight: normal;
}

//--

.medium{
  font-weight: 500;
}

@mixin weight-medium(){
  font-weight: 500;
}

//--

.semibold{
  font-weight: 600;
}

@mixin weight-semibold(){
  font-weight: 600;
}

//--

.bold{
  font-weight: bold;
}

@mixin weight-bold(){
  font-weight: bold;
}

//--

.extrabold{
  font-weight: 800;
}

//all input elements for the contr flow would be tagged with the contr class.
//All these elements must be in noto serif
.kp-contr{
  // font-family: 'noto_serif', serif;
  font-family: 'noto_serif', 'noto_sans_devanagari' , sans-serif;
}

b, strong {
  font-weight: bold;
}


//letter-spacing classes
.ls-10{
  letter-spacing: 0.1em;
}

.ls-minus-03{
  letter-spacing: -0.03em;
}

@mixin ls-10(){
  letter-spacing: 0.1em;
}

@mixin ls-0(){
  letter-spacing: 0em;
}

@mixin ls-minus-03(){
  letter-spacing: -0.03em;
}

@mixin ls-minus-02(){
  letter-spacing: -0.02em;
}

@mixin ls-minus-01(){
  letter-spacing: -0.01em;
}

//casing
.u-case{
  text-transform: uppercase;
}

@mixin u-case(){
  text-transform: uppercase;
}

@mixin l-case(){
  text-transform: lowercase;
}

@mixin t-case(){
  text-transform: capitalize;
}

@mixin l-case(){
  text-transform: lowercase;
}

//alignment
.align-center{
  text-align: center;
}

@mixin align-center(){
  text-align: center;
}

//--

@mixin h1(){
  font-size: ms(6);
  line-height: 1.5em;
  @include serif();

  @include mediaQuery('lg'){
    font-size: ms(9);
    line-height: 1.5em;
  }
}

.h1{ @include h1() }

.h1.sans-serif{
  @include h1();
  @include sans-serif();
}

.OKE-markdown > h1{ @include h1() }

//--

@mixin h2(){
  font-size: ms(4);
  line-height: 1.5em; 
  @include serif();

  @include mediaQuery('lg'){
    font-size: ms(6);
    line-height: 1.5em;
  }
}

.h2{ @include h2() }

.h2.sans-serif{
  @include h2();
  @include sans-serif();
}

.OKE-markdown > h2{ @include h2() }

//--

@mixin h3(){
  font-size: ms(2);
  line-height: 1.6em;
  @include serif();

  @include mediaQuery('lg'){
    font-size: ms(4);
    line-height: 1.6em;
  }
}

.h3{ @include h3() }

.h3.sans-serif{
  @include h3();
  @include sans-serif();
}

.OKE-markdown > h3{ @include h3() }

//--

@mixin h4(){
  font-size: ms(1);
  line-height: 1.6em;
  @include serif();

  @include mediaQuery('lg'){
    font-size: ms(2);
    line-height: 1.6em;
  }
}

.h4{ @include h4() }

.h4.sans-serif{
  @include h4();
  @include sans-serif();
}

.OKE-markdown > h4{ @include h4() }

//--

@mixin p(){
  font-size: ms(-1);
  line-height: 1.7em;
  @include serif();

  @include mediaQuery('lg'){
    font-size: ms(0);
    line-height: 1.7em;    
  }
}

.p{ @include p() }

.p.sans-serif{
  @include p();
  @include sans-serif();
}

.OKE-markdown > p{ @include p() }

//--

@mixin li(){
  @include p();
}

.li{ @include li() };

.li.sans-serif{
  @include li();
  @include sans-serif();
}

.OKE-markdown > li{ @include li() }

//--

@mixin h5(){

  font-size: ms(-1);
  line-height: 1.6em;
  @include sans-serif();

  @include mediaQuery('lg'){
    font-size: ms(-1);
    line-height: 1.6em;
  }
}

.h5{ @include h5() }

.OKE-markdown > h5{ @include h5() }

//-- by default h5 is sans-serif unlike all other base type settings

@mixin h6(){
  font-size: ms(-2);
  line-height: 1.6em;
  @include sans-serif();

  @include mediaQuery('lg'){
    font-size: ms(-2);
    line-height: 1.6em;   
  }
}

.h6{ @include h6() }

.OKE-markdown > h6{ @include h6() }

//--

@mixin h7(){
  font-size: ms(-4);
  line-height: 1.6em;
  @include sans-serif();

  @include mediaQuery('lg'){
    font-size: ms(-4);
    line-height: 1.6em;   
  }
}

.h7{ @include h7() }

//--

@mixin titleSans--L(){
  @include h2();
  @include sans-serif();
}

.titleSans--L{
  @include titleSans--L();
}

@mixin titleSerif--L(){
  @include h2();
}

.titleSerif--L{
  @include titleSerif--L();
}

//--

@mixin titleSans--M(){
  @include h3();
  @include sans-serif();
}

.titleSans--M{
  @include titleSans--M();
}

@mixin titleSerif--M(){
  @include h3();
}

.titleSerif--M{
  @include titleSerif--M();
}

//--

@mixin blockquote(){
  padding: 2rem;
  border-left: 4px solid $primaryColor100;
  @include h4();
  @include italic();
}

.blockquote{
  @include blockquote();
}

@mixin summary(){
  font-size: ms(0);
  line-height: 1.5em;
  @include italic();

  @include mediaQuery('lg'){
    font-size: ms(1);
  }
  
}

.summary{
  @include summary();
}

@mixin titleSerif--S(){
  @include h4();
}

.titleSerif--S{
  @include titleSerif--S();
}

@mixin cardTitle(){
  @include h4();
  font-size: ms(-1);
  line-height: 1.6em;
}

.cardTitle{
  @include cardTitle();
}

@mixin titleSans--S(){
  @include h4();
  @include sans-serif();
}

.titleSans--S{
  @include titleSans--S();
}

@mixin titleSans--S--Bold(){
  @include h4();
  @include sans-serif();
  @include weight-bold();
}

.titleSans--S--Bold{
  @include titleSans--S--Bold();
}

//--

@mixin bodySerif--L(){
  @include p();
}

.bodySerif--L{
  @include bodySerif--L();
}

@mixin bodySans--L(){
  @include p();
  @include sans-serif();
}

.bodySans--L{
  @include bodySans--L();
}

@mixin sublabel(){
  @include h5();
  @include italic();
  @include sans-serif();
}

.sublabel{
  @include sublabel();
}

//--

@mixin bodySerif--M(){
  @include h5();
  @include serif();
}

.bodySerif--M{
  @include bodySerif--M()
}

@mixin bodySans--M(){
  @include h5();
}

.bodySans--M{
  @include bodySans--M()
}

@mixin bodySans--M--Bold(){
  @include h5();
  @include weight-bold();
}

.bodySans--M--Bold{
  @include bodySans--M--Bold()
}

@mixin inputText(){ //dropdowns and all othe rinputs
  @include h5();
}

.inputText{
  @include inputText();
}

@mixin linkText(){
  @include h5();
}

.linkText{
  @include linkText();
}

@mixin linkTextInline(){
  @include h5();
}

.linkTextInline{
  @include linkTextInline();
}

@mixin headerLink--primary(){
  @include h7();
  @include ls-10();
  @include u-case();
  @include weight-semibold();
}

.headerLink--primary{
  @include headerLink--primary();
}

@mixin headerLink--secondary(){
  @include h6();
  @include weight-medium();
}

.headerLink--secondary{
  @include headerLink--secondary();
}


//--

@mixin label--L(){
  @include h6();
  @include ls-10();
  @include u-case();
  @include weight-semibold();
}

.label--L{
  @include label--L();
}

.OKE-markdown strong{ @include label--L() }

@mixin meta(){
  @include h6();
}

.meta{
  @include meta();
}

//--

@mixin label--M(){
  @include h7();
  @include ls-10();
  @include u-case();
  @include weight-bold();
}

.label--M{
  @include label--M();
}

//--

@mixin caption(){
  @include h6();
}

.caption{
  @include caption();
}

//--

@mixin link(){
  .kp-link-content__text{
    @include h5();
    @include weight-normal();
  }
}

.link{
  @include link();
}

@mixin footerLink(){
  .kp-link-content__text{
    @include h5();
    @include weight-normal();
    @include sans-serif();
  }
}

.footerLink{
  @include footerLink();
}

@mixin primaryHeaderLink(){
  @include h5();
  // // @include weight-semibold();
  // @include sans-serif();
}

.primaryHeaderLink{
  @include primaryHeaderLink();
}







@mixin buttonText(){
  @include h5();
  @include weight-semibold();
  @include sans-serif();
}

.buttonText{
  @include buttonText();
}




//--

.h5.serif{
  font-size: 12px;
  line-height: 1.6em;

  @include mediaQuery('lg'){
    font-size: 14px;
    line-height: 1.6em;
  }
}



@mixin h5-serif(){
  font-size: 12px;
  line-height: 1.6em;

  @include mediaQuery('lg'){
    font-size: 14px;
    line-height: 1.6em;
  }
}

.h6.serif{
  font-size: 12px;
  line-height: 1.6em;

  @include mediaQuery('lg'){
    font-size: 12px;
    line-height: 1.6em;
  }
}



@mixin h6-serif(){
  font-size: 12px;
  line-height: 1.6em;

  @include mediaQuery('lg'){
    font-size: 12px;
    line-height: 1.6em;
  }
}


.p.serif, .li.serif{

  font-size: 16px;
  line-height: 1.6em;

  @include mediaQuery('lg'){
    font-size: 18px;
    line-height: 1.6em;
  }
}

ul > li.show-list-style {
  list-style: disc
}

.show-list-style-image--kp-checklist{
  ul {
    padding-left: 0;
    > li.show-list-style {
      // list-style-image: url("../images/checklist-list-style-img.svg")
      background: url("../images/checklist-list-style-img.svg") no-repeat left 3px; /* <-- change `left` & `top` too for extra control */
      padding-left: 3rem;
      /* reset styles (optional): */
      list-style: none;
      margin: 0
    }
  }
}

ol > li.show-list-style {
  list-style: decimal
}

ul, ol{
  font-size: 16px; //we need these font sizes ( matching the font sizes of 'p') only so that em works properly on the mixin below

  @include mediaQuery('lg'){
    font-size: 18px;
  }
}

@mixin rich-text-space-after(){
  margin-bottom: $pSerifFontsize_M * 1.6;

  @include mediaQuery('lg'){
    margin-bottom: $pSerifFontsize_D * 1.6
  }
}

.rich-text-space-after{
  margin-bottom: 6px;

  @include mediaQuery('lg'){
    margin-bottom: 6px;
  }
}






@mixin hintsText(){
  @include h5();
  @include sans-serif();
  // @include italic();
}

.hintsText{
  @include hintsText();
}



@mixin inputTitle(){
  @include inputText();
  @include weight-semibold();
}

.inputTitle{
  @include inputTitle();
}

@mixin inputDescription(){
  @include inputText();
}

.inputDescription{
  @include inputDescription();
}


//-new--//
