.kp-like-org-and-designation-container.display{
  display: flex;
  // align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  // justify-content: center;

  .kp-text-input-display{
      @include h6();
      @include weight-bold();
      @include u-case();
      @include ls-10();
      color: $greyColor70;
  }

  .kp-link-content__text{
    @include h6();
    @include weight-bold();
    @include u-case();
    @include ls-10();
  }

  .kp-profile-img-display{
    display: none;
  }
}
