.kp-loader-wrapper{

  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $white;

  &.page-loader{
    position: fixed;
    background-color: $white;
    z-index: 10000000;
  }

  &.inline-loader{
    position: relative;
    background-color: $none;
  }

  .kp-loader{
    animation: spin 1s linear infinite;
    border-radius: 50%;
  }

  &.large.color-primary{
    .kp-loader{
      border: 0.5rem solid $none;
      border-top: 0.5rem solid $primaryColor100;
      border-right: 0.5rem solid $primaryColor100;
      border-bottom: 0.5rem solid $primaryColor100;
      width: 5rem;
      height: 5rem;
    }
  }

  &.small.color-primary{
    .kp-loader{
      border: 0.3rem solid $none;
      border-top: 0.3rem solid $primaryColor100;
      border-right: 0.3rem solid $primaryColor100;
      border-bottom: 0.3rem solid $primaryColor100;
      width: 3rem;
      height: 3rem;
    }
  }

  &.small.color-white{
    .kp-loader{
      border: 0.3rem solid $none;
      border-top: 0.3rem solid $white;
      border-right: 0.3rem solid $white;
      border-bottom: 0.3rem solid $white;
      width: 3rem;
      height: 3rem;
    }
  }

  &.small.color-grey15{
    .kp-loader{
      border: 0.3rem solid $none;
      border-top: 0.3rem solid $greyColor15;
      border-right: 0.3rem solid $greyColor15;
      border-bottom: 0.3rem solid $greyColor15;
      width: 3rem;
      height: 3rem;
    }
  }



}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


//modifiers
.kp-loader-wrapper.full-screen-minus-header{
  margin-top: $primaryHeaderHeight_M;

  @include media768(){
    margin-top: $primaryHeaderHeight_D;
  }
}
