.inlineAlert{
    display: flex;
    align-items: center;

    .inlineAlert__icon{
        padding-right: 0.5rem;
        display: flex;
        align-items: center;
    }

    .inlineAlert__text{
        // padding: '0 .5rem';
    }
}
