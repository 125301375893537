.checkboxElement{
  width: 2rem;
  height: 2rem;
  border: 2px solid $primaryColor100;
  border-radius: 2px;
  margin-right: 1rem;
  background-color: $none;
  @include kp-transition-2(background-color, 0.2s);

  &.selected{
    background-color: $primaryColor100;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .checkboxElement__check{
    flex: 1 0 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
}