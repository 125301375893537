.ListEl-Wrapper--Content{
  

  .ListEl--Content{
    padding-top: 3rem;
    padding-bottom: 3rem;  
    border-bottom: 1px solid $greyColor15;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    position: relative;

    
    .ListEl__title{
      &.-has-media-thumb-{
        //exception media query situation - 1
        @media (max-width: 399px) {
          padding-right: 8rem;
        }
      }
      
    }

    &:hover{     
      .ListEl__title{
        color: $primaryColorText;
      }

      .ListEl__title .ListEl__title-span{
        //animate the underline created by the mixin : underlineForAnimation
        background-size: 100% 100%;
      }
    }
  }

  .ListEl__contentModule-2{
    display: flex;
    align-items: center;
    //exception media query situation - 2
    @media (max-width: 399px) {
      position: absolute;
      right: 0;
    }
    
  }



  .ListEl__label{
    padding-bottom: 1rem;
  }

  .ListEl__date{
    padding-bottom: 0.5rem;
    .cardDateText {
      @include bodySans--L();
      @include weight-bold();
    }
    
  }

  .ListEl__title{
    padding-bottom: 1rem;
    @include kp-transition(color);

    .ListEl__title-span{
      @include underlineForAnimation(5, 0.5s)
    }
  }

  .ListEl__metaBlock{
    padding: 0;
  }

  .ListEl__image-container, .ListEl__video-container{
    width: 70px;
    height: 84px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    margin-left: 2rem;

    //exception media query situation - 3
    @media (max-width: 399px) {
      width: 6rem;
      height: 6rem;
    }
  
    .kp-image-input, .kp-video-embed {
      width: 140px
    }
  }

}


