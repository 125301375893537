//#1 card--story dimensions config
$userProfilesCardHeight: 32rem;



$userProfilesCardMinWidth: 30rem;

//temp
.kp-in-editor-card-links-display--internal{
  .kp-content-card-wrapper.published--userProfiles{
    .kp-content-card{
      border: 2px solid $greyColor5;
      background-color: $greyColor5;
      text-align: center;
      
      &:hover{
        background-color: $none;
        @include no-box-shadow();
        transform: scale(1);
      }

      .kp-profile-img-display{
        margin: 0 auto;
        margin-top: 2rem;
      }

      .kp-meta-primary{
        display:none
      }

      .kp-content-card__summary{
        display: none;
      }

      .kp-content-card__tags{
        display: none;
      }
    }
  }
}


.kp-content-card-wrapper{
  &.profile--userProfiles, &.profile--organisations, &.profile--board{
  min-width: $userProfilesCardMinWidth;
  margin-top: 5rem;

    .kp-content-card{
      overflow: visible;

      @include media576(){
        // height: $userProfilesCardHeight;
      }

      .kp-content-card__content{
        display: flex;
        flex-direction: column;
        // align-items: center;

        .kp-content-card__profile-img{
          margin-top: -5rem;
          align-self: center;
          margin-bottom: 1.5rem;
        }

        //this is for org
        .kp-content-card__img-container{
          margin-top: -5rem;
          display: flex;
          justify-content: center;
          margin-bottom: 1.5rem;
        }


        .kp-content-card__text-wrapper{
          flex-grow: 1;
          padding-top: 0;
          padding-bottom: 1.5rem;

          .kp-content-card__title{
            @include titleSans--S();
            // @include sans-serif();
            // @include h4-plus();
            text-align: center;
            // @include kp-clamp-text(2);
          }

          .kp-content-card__meta-primary-post-title{
            justify-content: center;
            padding-top: 0.5rem;
            

            .kp-meta-primary__text--value{
              @include h6();
              @include l-case();
              @include ls-0();
              @include weight-semibold();
              color: $greyColor70;
            }

            .kp-meta-primary__text--key{
              color: $primaryColorText;
            }
          }

          .kp-content-card__summary{
            padding-top: 1rem;
            @include bodySans--M();
            @include kp-clamp-text(4);
            text-align: center;
          }

          .kp-content-card__meta-secondary{
            padding-top: 0.5rem;
            color : $greyColor70;
          }

          .kp-content-card__tags{
            .kp-selected-comps-group{
              flex-wrap: nowrap;

              >div{
                flex: 1 0 auto;
              }
            }
          }
        }
      }
    }
}

&.profile--userProfiles, &.profile--board{
  .kp-content-card__content{
  .kp-content-card__text-wrapper{
  .kp-content-card__meta-primary-post-title{
    display: block;

    .kp-meta-primary__text--value{
      text-align: center;
    }

    .kp-meta-primary__text--key{
      text-align: center;
    }
  }
  }
  }
}

}
