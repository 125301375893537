//style setting when an in-editor block is hovered on
.show-block-actions{
  position: relative;
  outline: 1px solid $primaryColor100;
}

.block-actions-wrapper{
  position: absolute;
  top: 2rem;
  right: 2rem;
}
