.Card-Wrapper--Content{
  
  padding-top: 1rem;
  padding-bottom: 1rem;

  @include mediaQuery('sm'){
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  @include mediaQuery('md'){
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  
  .Card--Content{
    background-color: $greyColor5;
    // border: 2px solid $greyColor5;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
    @include kp-multi-transition(background-color);

    &:hover{
      background-color: $white;
      // color: $white;
      // @include box-shadow-2();
      // box-shadow: -8px 8px 1px $greyColor10;
      // transform: scale(1.015);

      .Card__action{
        .kp-icon-btn::before{
          background-color: $greyColor100;
        }
      }

    }

    &::before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border: 2px solid $greyColor5;
      z-index: 1;
    }

    
    .Card__contentModule-1{
      padding: 2rem;
      padding-bottom: 3rem;
      flex-grow: 1;

      .Card__label{
        padding-bottom: 1.5rem;
      }
      
      .Card__date{
        padding-bottom: 1rem;
      }
  
      .Card__title{
        @include kp-clamp-text(7);
      }

      .Card__action{
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .Card__contentModule-2{

      .Card__metaBlock{
        padding: 0 2rem 2rem 2rem;
      }

      .Card__image-container, .Card__video-container{
        height: 100px;
        display: flex;
        align-items: center;
        overflow: hidden;
      
        .kp-image-input, .kp-video-embed {
          flex-grow: 1;
        }
      }

    }

  }
  
}
