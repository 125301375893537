//#1 card--story dimensions config
$newsCardHeight: 45rem;
$newsCardMinWidth: 35rem;


//targetted styling for different card 'types'

.kp-content-card-wrapper{

  //1
  &.published--news{
    // min-width: $newsCardMinWidth;

    .kp-content-card{
      @include media576(){
        // height: $newsCardHeight;

        .kp-content-card__img-container{
          padding: 2rem 0 0 2rem;
          width: 50%;

        }

        .kp-content-card__content{
          display: flex;
          flex-direction: column;

          .kp-content-card__text-wrapper{
            flex-grow: 1;
          }
        }
      }

      .kp-content-card__text-wrapper{
        height: 100%;
        padding-bottom: 2rem;
        // padding: 3rem 6rem;
        .kp-content-card__meta-secondary{
          margin-top: 1.5rem;
          margin-bottom: 1rem;
          @include kp-clamp-text(1);
        }

        .kp-content-card__title{
          padding-top: 0rem;
          @include kp-clamp-text(4);
        }

        .kp-content-card__meta-group{
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 2rem;

          @include media576(){
            padding-top: 0;
          }


          .kp-content-card__meta-group-child{
            width: 80%;

            .kp-meta-block-contents-wrapper{
              padding-top: 0;
            }
          }

          @include resourceTypeCardActions($primaryColor100, $white);
        }
      }

      .kp-content-card__summary{
        @include summary();
        @include kp-clamp-text(1000); //basically we are unsetting the lineclamp limit
        padding-top: 3rem;
      }


    }
  }

}
