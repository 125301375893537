.kp-intro-section{
  position: relative;
  background: #f5f5f5;
  width: 100%;
}

.kp-publish-page-type{
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  padding: 2rem 0;
  
  .kp-publish-page-type--key{
    color: #747474;
    font-size: 10.6px;
    margin-right: 1rem;
    font-weight: bold;
  }
  .kp-publish-page-type--value{
    color: #0071cc;
    font-size: 10.6px;
    font-weight: bold;
  }
}

.kp-publish-page-title-and-subtitle-wrapper{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .kp-publish-page-title{
    font-size: 3.2rem;
    text-align: center;
    margin-bottom: 2rem;
    font-family: 'noto_serif';
  }
  .kp-publish-page-subtitle{
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 4rem;
  }
}

.kp-publish-meta-primary-and-actions-wrapper{
  border-top: 1px solid #e1e1e1;
  margin: 2rem 0 2rem 0;
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  .kp-publish-meta-primary--details{
    display: flex;

    .kp-publish-meta-primary--details-name, 
    .kp-publish-meta-primary--details-date,
    .kp-publish-meta-primary--details-location {
      font-size: 12.8px;
      color: #383838;
      margin-right: .5rem;
    }
  }

  .kp-publish-actions{
    width: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.body-navbar{
//  padding-top: 2rem;
  margin-top: 6rem;
}
.kp-publish-side-bar{ 
    position: sticky;
    top: 0%;
    .side-bar-text{
      font-size: 12.8px;
      color: #383838;
      margin-bottom: 10px;
      cursor: pointer;
      display: block;
    }
  
}

  .active{
      color: #0071cc;
      font-style: bold;
    }


.label{
      font-size: 12.8px;
      color: #747474;
      text-transform: uppercase;
      margin-bottom: 1rem;
      font-weight: bold;
    }
    .text-italic{
        font-size: 20px;
        font-style: italic;
        color: #383838;
    }
    
    // background: #f5f5f5;
    .tpl-block{
      margin-bottom: 4rem;
    }
.kp-publish-body-content{
    position: relative;
    .img-div{
      position: relative;
      width: 100%;
      height: 400px;
      background: #e1e1e1;
      margin: 2rem 0;
    }
    .summary{
      padding-top: 2rem;
      padding-bottom: 4rem;
      border-bottom: 1px solid #e1e1e1;
      margin-bottom: 4rem;
      
    }
}
.divider{
  margin: 2rem 0 3rem 0;
  height: 1px;
  background-color: #e1e1e1;
}

.tag-section{
  margin: 4rem 0;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  padding: 4rem 0;
  // @include pageContainer();
  .tag-label{
    margin-bottom: 2rem;
    font-size: 12.8px;
    color: #747474;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.community-tags-block{
  // @include pageContainer();
  .commnibut-tag__profile{
   display: flex;
   justify-content: flex-start;
   align-items: center;
   margin-bottom: 2rem;
    &-img{
       width: 40px;
       height: 40px;
       background: #747474;
       border-radius: 50%;
       margin-right: 1rem;
    }
    &-name{
      font-size: 16px;
      color: #383838;
    }
  }
}

.tags-block{
  margin-top: 4rem;
  // @include pageContainer();
  .tag{
    display: flex;
    &-text{
      font-size: 16px;
      border: 2px solid #e1e1e1;
      border-radius: 2.5rem;
      padding: 1rem 2rem;
      margin: 1rem;
    }
  }
}
