.kp-share-actions-wrapper{
  

  .kp-share-actions__modal-body{
    padding: 2rem 2rem;

    @include mediaQuery('sm'){
      padding: 2rem 6rem;
    }

    .kp-input-container{
      width: 100%;
    }
  }
  .kp-share-actions__soc-media-btns-group{
    display: flex;
    justify-content: center;

    .ShareActions__socialBtn{
      margin-right: -2px;
  
      &:last-of-type{
        margin-right: 0;
      }
    }
  }
  
}
