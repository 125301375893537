.kp-profile-summary__contact-channels, .kp-profile-summary__actions{
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
}

.kp-profile__summary-sidebar-wrapper{
  position: relative;
  // max-width: 100%;
  // width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  // display: flex;
  // justify-content: center;
  margin-top: -6rem;

  @include mediaQuery('lg'){
    width: 32rem;
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    top: 15rem;
    @include kp-multi-transition(left);

    &.collapse-for-body{
      left: -32rem
    }
  }

  

  .kp-profile__summary-sidebar{
    // flex: auto auto auto;
    width: 100%;
    // border-radius: 2rem;
    background-color: $white;
    @include box-shadow-2();

    @include media768(){
      // flex: 0 0 50rem;
    }

    @include media992(){
      // height: 100vh; //fallback for browsers that dont support calc.
      // margin-top: -($primaryHeaderHeight_D + $secondaryHeaderHeight_D);
      // padding-top: $primaryHeaderHeight_D + $secondaryHeaderHeight_D;
      // overflow: scroll;
      // border-right: 1px solid $greyColor15;
    }
  }

  .kp-profile-summary__action-btn--edit, .kp-profile-summary__action-btn--delete {
    position: absolute;
    top: 0;
    right: 9rem;
    transform: translate(50%, -50%);

    &::before{
      border: 1px solid $white
    }
  }

  .kp-profile-summary__action-btn--delete{
    right: 4rem
  }
}

// .kp-profile__summary-sidebar{
//   .kp-container-fluid{
//     padding: 2rem;
//   }
// }


// @include media992(){
//     .kp-profile__summary-sidebar{
//       height: 100vh; //fallback for browsers that dont support calc.
//       margin-top: -$primaryHeaderHeight_D;
//       padding-top: $primaryHeaderHeight_D;
//       overflow: scroll;
//       // align-self: flex-start;
//       border-right: 1px solid $greyColor15;
//       // position: sticky;
//       // .kp-container-fluid{ //this container fluid is defined in the view/edit profileTplBlockGenerator comp
//       //   padding: 6rem;
//       // }
//     }
// }

//overrides on og ProfileCard Comp
.kp-profile-summary__user-profile-card{
  .kp-profile-card{
    @include no-box-shadow();
    height: auto;

    @include media768(){
      .kp-profile-card__img-plus-text-wrapper{
        .kp-profile-card__text-wrapper{
          .kp-profile-card__desc{
            -webkit-line-clamp: 4;
          }
        }
      }
    }
  }
}
