.kp-video-embed__preview{
  padding-top: 2rem;
}

.kp-video-embed__player{
  padding-top: 56.25%;
  position: relative;
}



.kp-video-embed__display, .kp-video-embed__preview{
  > div > div {
    position: absolute;
    top: 0;
    left: 0;
  }
}
