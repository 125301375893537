.kp-hints-block-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  // width: 100%;
  height: 5rem;
  position: absolute;
  right: 0;
  bottom: 0;

  .kp-hints-btn {
    margin: 0;
    height: 100%;
    border-radius: 0;
    // background-color: $primaryColor100 !important;
    // border: 0;
    // color: $white;
    // box-shadow: none;
    z-index: 50000000;
    

    @include media768() {

      // height: 5rem !important;

    }
  }

  .kp-hints-list-wrapper--m {
    background-color: $white;
    // margin-bottom: -5rem;
    width: 100vw;
    height: 11rem;
    @include box-shadow-3();
    display: flex;
    flex-direction: column;
    text-align: center;
    // wrote by rahul
    position: absolute;
    bottom: 5rem;
    right: 0;
    overflow: hidden;

    @include noTextSelect();

    &.kp-hints-list-wrapper--m-closed {
      display: none
    }

    .kp-hints-ul--m {
      padding: 0;
      display: flex;
      flex-grow: 1;
      align-items: center;

      .kp-hint-li--m {
        @include kp-transition(transform);
        min-width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }

  .kp-hints-btn {

    z-index: 1000;

  }
}

//hints list desktop
.kp-hints-list-wrapper--d {
  background-color: white;
  width: 24rem;
  position: absolute;
  right: 0;


  @include box-shadow-1();
  @include noTextSelect();

  &.kp-hints-list-wrapper--d-open {
    visibility: visible;
  }

  &.kp-hints-list-wrapper--d-closed {
    // transition: visibility .1s linear .2s, bottom .2s ease-out, opacity .2s ease-out;
    visibility: hidden;
    // opacity: 0;
    // // bottom: 1.5rem;
    // transform: translateX(100%);
  }

  .kp-hints-ul-wrapper--d {
    display: flex;
    align-items: end;
    overflow: hidden;
    padding: 0;

    .kp-hints-ul--d {
      @include kp-transition(transform);
      min-width: 100%;
      padding: 0 2rem;

      .kp-hints-li--d {
        padding: 2rem 0;
        border-bottom: 1px solid $greyColor15;
      }
    }
  }

  .kp-multi-slide-indicator--d {
    text-align: center;
  }
}