.kp-table{
    .disabled-cell-style{
        background-color: $greyColor5;
    }

    .kp-table__cell{
        padding: 0.5rem 1rem;
    
        .public-DraftEditor-content{
            font-size: 12px;
            @include sans-serif();
        }
    }
    
    .kp-table__cell--header{
        background-color: $primaryColor10;
        .public-DraftEditor-content{
            font-weight: bold;
        }
    }
}
