.EventsSidebar{
    background-color: $greyColor100;
    padding: 2rem;
    margin-top: 3rem;

    @include oke-negative-hor-margin('sm');
    

    @include mediaQuery('lg'){
        @include oke-negative-hor-margin-0()
    }
    

    .EventsSidebar__sectionTitle{
        color: $white;
    }
}