


.OKE-Footer {
  padding-top: 6rem;
  padding-bottom: 6rem;
  text-align: center;

  @include mediaQuery('sm'){
    text-align: left;
  }

  .OKE-Footer__sectionTitle{
    color: $greyColor40;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .OKE-Footer__section{
    margin-top: -0.5rem;
    margin-bottom: 2.5rem; // actully 3rem. half rem is being added by the last navLink

    &:last-of-type{
      margin-bottom: 0;
    }

    .navLink{
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;

      .navLink__link{
        color: $white;
        @include bodySans--M(); 
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;

        &:hover{
          text-decoration: underline;
        }
      }
    }
  }
  

  .OKE-Footer-Row{
    margin-top: -3rem;
    margin-bottom: -3rem;

    .OKE-Footer__col{
      padding-top: 3rem;
      padding-bottom: 3rem;

      &.OKE-Footer__col--about{
        color: $white;
        @include mediaQuery('md'){
          padding-right: 6rem;
        }

        .OKE-Footer__aboutText-wrapper {

          .OKE-Footer__markdown{
            .OKE-markdown{
              p,a{
                @include bodySans--M()
              }
              a{
                color: $primaryColorText
              }
            }
          }
        }
      }

      &.OKE-Footer__col--links{
        border-top: 1px solid $greyColor80;

        @include mediaQuery('sm'){
          border-top: none;
        }
      }
    }
  }
}

.OKE-Footer__joinMailingListBtn{
  margin: 2rem auto;
  width: 100%;
  max-width: 20rem;
  padding: 0 !important;

  @include mediaQuery('sm'){
    margin: 2rem 0;  
  }
}

.OKE-Footer__socialLinks{
  padding-top: 1rem;
  justify-content: center;

  @include mediaQuery('sm'){
    justify-content: flex-start;
  }
  

  .kp-link-wrapper{
    margin: 0;
    padding-top: 0;

    .kp-link .kp-link-content .kp-link-content__icon{
      padding-right: 0;
    }
  }
}
