.kp-ppt-embed__preview{
  display: flex;
  // justify-content: center;
  padding-top: 2rem;
}


.kp-ppt-embed-wrapper{
  position: relative;
  padding-top: 62%;
  height: 0;
  width: 100%;

  .kp-ppt-embed{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.punch-viewer-container{
  background-color: $white;
}
