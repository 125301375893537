.kp-contribute-settings-wrapper{
    &.kp-master-section--question-tags, //q-tags is editor facing
    &.kp-master-section--questions{ //qs is questionnaire user facing
  
      .kp-labels-wrapper{
        padding-bottom: 2rem;
  
        .kp-label{
          .kp-label__text{
            @include titleSerif__S();
            @include sans-serif();
            @include weight-bold();
            text-transform: none;
            @include ls-0();
            // @include weight-normal(); 
            color: $greyColor100;
            width: 100%;
  
          }
        }
      }
  
  
      .kp-radio-input-wrapper{
        .kp-radio-input{
          display: block;
        }
      }
    }
  
    &.kp-master-section--question-tags{
      .kp-radio-input__custom-comp{
  
        .kp-label .kp-label__text{
          @include bodySerif--L;
          @include weight-normal();
          color: $greyColor70;
        }
  
        .q-user-answer-ops-ref{
          padding: 2rem;
          border-radius: 0.5rem;
          background-color: $greyColor5;
          margin-bottom: 2rem;
  
          .kp-labels-wrapper{
            padding-bottom: 1rem;
  
            .kp-label {
              padding-bottom: '1rem';
              .kp-label__text{
                @include h6();
                @include u-case;
                @include ls-10();
              }
            }
          }
          
  
          .kp-list {
            display: flex;
    
            .kp-list__item{
              @include h6();
              @include italic();
              color: $greyColor70;
              padding-right: 2rem;
            }
          }
        }     
  
      }
    }
  
    // &.kp-master-section--thematic-select{
    // }
  }