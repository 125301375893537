//#1 card--story dimensions config
$wgCardHeight: 37rem;



$wgCardMinWidth: 30rem;


.kp-content-card-wrapper{
  &.profile--workingGroups, &.profile--stateNetworks, &.profile--partnerNetworks{
  min-width: $wgCardMinWidth;
  // margin-top: 5rem;

    .kp-content-card{
      // overflow: visible;

      @include media576(){
        // height: $wgCardHeight;
      }

      .kp-content-card__content{
        display: flex;
        flex-direction: column;
        // align-items: center;

        .kp-content-card__img-container{
          margin-bottom: 1.5rem;
        }

        .kp-content-card__text-wrapper{
          flex-grow: 1;
          padding-top: 0;
          padding-bottom: 2rem;

          .kp-content-card__title{
            @include titleSerif--S();
            // text-align: center;
            // @include kp-clamp-text(2);
          }

          .kp-content-card__summary{
            @include bodySans--M();
            padding-top: 1rem;
            @include kp-clamp-text(4);
            // text-align: center;
          }

          .kp-content-card__multi-profiles-display{
            .kp-link-wrapper{
              max-width: 36%;
              .kp-link{
                width: 100%;
                .kp-link-content{
                  width: 100%;
                  .kp-link-content__text{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
          }

        }
      }
    }


}
}
