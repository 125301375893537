//#1 card--story dimensions config
$eventCardHeight: 40rem;



//targetted styling for different card 'types'

  .kp-content-card-wrapper{

    //1
    &.published--events{

      .kp-content-card{
        @include media768(){
          // height: $eventCardHeight;
        }

        .kp-content-card__content{
          display: flex;
          flex-direction: column;

          @include kp-events-meta-hero-styling();

          .kp-content-card__text-wrapper{
            height: 100%;

            .kp-content-card__title{
              padding-top: 1rem;
              @include kp-clamp-text(4);
            }
          }
        }



        .kp-meta-primary{
          // padding-top: 3rem;
        }

        .kp-meta-secondary{
          padding-top: 2rem;
        }

        .kp-meta-block__meta-wrapper{
          max-width: 100%;
        }


      }
    }

}
