.kp-external-link-card{
  // padding: 2rem;
  display: flex;
  align-items: center;
  border: 0.5px solid $greyColor15;
  border-radius: 1rem;
  background-color: $white;
  overflow: hidden;
  @include box-shadow-0();
  @include kp-transition(all);

  &:not(.no-hover){
    cursor: pointer;

    &:hover{
      transform: scale(1.015);
    }
  }

  .kp-external-link-card__img{
    width: 20rem;
    // height: 16rem;
    // border-radius: 1.5rem;
    margin-right: 2rem;
    flex-shrink: 0;
  }

  .kp-external-link-card__text-group{
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 2rem;


    .kp-external-link-card__source{
      margin-bottom: 1rem;
      color: $greyColor70;
    }

    .kp-external-link-card__title{
      @include kp-clamp-text(2);
      margin-bottom: 1rem;
    }

    .kp-external-link-card__description{
      @include kp-clamp-text(2);
      margin-bottom: 1rem;
    }

    .kp-external-link-card__url,{
      color: $greyColor70;

    }
  }


}
