.AuthForm__title{
  display: none;
  padding-bottom: 2rem;

  @include media992(){ display: block }
}

.kp-login-register-form__input{
  padding-bottom: 2rem;
}
