.kp-profile--edit{
  padding-bottom: 30rem;
  .Tpl__subsection:not(.Tpl__coverSection){
    .Tpl__subsection-container{
      @include pageContainer();
      overflow: unset;
      .Tpl__subsection-row{
        @include pageRow();
        .Tpl__subsection__{
          width: 100%;
        }
        .kp-tpl-block, .Tpl__subsectionTitleModule{
          padding-top: 3rem;
          padding-bottom: 3rem;
          @include oke-col-0();
          @include oke-col(10, "lg");
          @include oke-offset(1, "lg");
          @include oke-col(6, "xl");
          @include oke-offset(3, "xl");

          &.kp-tpl-block--avatar{
            display: flex;
            justify-content: center;
            .avatar{
              width: 24rem;
            }
            
          }
        }
      }
    }
  }
}