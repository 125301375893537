.introTags{
  display: flex;
  justify-content: center;
  margin-left: -1rem;
  margin-right: -1rem;

  .introTags__tag{
    color: $greyColor40;
    padding: 0.5rem 1rem;
    border: 1px solid $greyColor40;
    border-radius: 2px;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}