.kp-full-screen-menu-header{
  height: 6rem;
  border-bottom: 1px solid $greyColor15;
  display: flex;
  align-items: center;
  padding: 0 2rem;

  .kp-full-screen-menu-header__left-comp,
  .kp-full-screen-menu-header__center-comp,
  .kp-full-screen-menu-header__right-comp{
    flex: 0.25
  }

  .kp-full-screen-menu-header__left-comp{
    display: flex;
    justify-content: flex-start;
  }

  .kp-full-screen-menu-header__center-comp{
    display: flex;
    justify-content: center;
  }

  .kp-full-screen-menu-header__right-comp{
    display: flex;
    justify-content: flex-end;
  }
}
