.kp-rich-editor{
  background-color: $none;
  @include p();
  // @include serif();
  // @include h4-serif();
  // @include weight-medium();

  &.kp-rich-editor-focussed{
    // padding: 2rem;
  }

  //draft js editor overides
  .DraftEditor-root{
    width: 100%;

    //last block shouldnt have standard bottom spacing
    .DraftEditor-editorContainer{
      z-index: auto;

      .public-DraftEditor-content{
        > div > :last-child {
          margin-bottom: 0;
        }
      }
    }

    .public-DraftEditorPlaceholder-root{
      // @include serif();
      // @include h4-serif();
      // @include weight-medium();
      color: $primaryColorText;
     

      height: 0;
      position: relative;
      // z-index: 1;

      .unordered-list-item-fixed-editor &{
        padding-left: 2rem;
      }
    }


    .DraftEditor-editorContainer{
      position: relative;
      // z-index: 2;
    }
  }


  .kp-editor-prefix{
    padding-right: 0.5rem;
    color: $primaryColorText;
  }

  .public-DraftEditor-content{ min-height: auto; }

}


//block-specific-styling


  .kp-story-title, .kp-title{
      //  @include oke-col(12, 'xl');
     
    .kp-rich-editor, span{
      @include titleSerif--L();
      font-weight: 400 !important;
      // margin-bottom: 1rem;
      // @include weight-semibold();
    }
  }

  .kp-story-subtitle, .kp-subtitle{
    margin-bottom: 4rem;
    .kp-rich-editor span{
      @include titleSerif--M();
    }
  }

  // .kp-toolkits-title{

  // }
