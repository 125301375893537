html{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-size: 10px;
  height: 100%;
}

body, #root, .kp-contribute-flow, .kp-container-fluid{
  // min-height: 100vh;
  color: $greyColor100;
}

#root{
  height: 100%;
  // overflow-x: hidden;
}

button:focus, input:focus, textarea:focus{
  outline: none;
}

a, a:hover{
  text-decoration: none;
  color: $greyColor100;
  cursor: pointer;
}

ul, ol { padding-left: 2rem; margin-bottom: 0;}
li { list-style: none; }

input::placeholder{
  color: $primaryColorText;
  opacity: 1;
}

textarea::placeholder{
  color: $primaryColorText;
  opacity: 1;
}

.hide{
  display: none !important;
}

.show{
  display: block !important;
}

.show-flex{
  display: flex !important;
}

.kp-formfield-wrapper{
  padding-top: 1rem;
}

.kp-container-fluid{
  width: 100%;
  padding: 2rem 2rem;
  background-color: $white;

  @media ( min-width: 768px){
    padding: 2rem 6rem;
  }
}

.kp-row{
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
  // margin-top: -1rem;
}

@mixin kp-row(){
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
}

.kp-col{
  padding-left: 1rem;
  padding-right: 1rem;
}

@mixin kp-col(){
  padding-left: 1rem;
  padding-right: 1rem;
}

.kp-offset-container{
  padding-left: 0;
  padding-right: 0;

  @include media768(){
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@mixin kp-offset-container(){
  padding-left: 0;
  padding-right: 0;

  @include media768(){
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.padding-top-2{
  padding-top: 2rem;
}

.padding-top-4{
  padding-top: 4rem;
}

.no-margin{
  margin: 0;
}

.kp-contribute-flow, .kp-overlay-page{
  position: relative;
  top: 0;
  width: 100%;
  z-index: 1000000;
  margin-top: -6rem; //height of primary header. its a hard code, should prob be improved
  background-color: $white;

  @include mediaQuery('lg'){
    margin-top: -10rem; //height of primary header. its a hard code, should prob be improved
  }
}



.-six-six-{ 
  .kp-contribute-flow, .kp-overlay-page{
    @include mediaQuery('lg'){
      margin-top: -6rem;
    }
  }
}

.kp-inline-link{
  font-size: inherit;
  font-family: inherit;
  @include weight-bold();
  color: $primaryColorText !important;
  text-decoration: none;
  position: relative;
  @include kp-transition(background-color);

  &:hover{
    background-color: $primaryColor10;
  }
}

.kp-no-link-styling{
  color: $greyColor100;
  font-family: inherit;
  font-weight: inherit;

  &:hover{
    color: $greyColor100;
    cursor: text;
  }
}

//login_register

.kp-auth-form__input-wrapper{
  margin-bottom: 3rem;
}

.kp-auth-form__submit-btn{
  width: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // margin: 0 auto;
  // margin-top: 2rem;

  .auth-form-loader{
    .kp-loader{
      border-right-color: $white;
      border-bottom-color: $white;
      border-top-color: $white;
    }
  }
}

//custom nested col padding settings
.kp-offset-1-in-8-col{
  padding-left: 2rem;
  padding-right: 2rem;

  @include media768(){
    padding-left: 12.4999% !important;
    padding-right: 12.4999% !important;
  }
}

@mixin kp-offset-1-in-8-col(){
  padding-left: 2rem;
  padding-right: 2rem;

  @include media768(){
    padding-left: 12.4999% !important;
    padding-right: 12.4999% !important;
  }
}

.kp-offset-1-in-10-col{
  padding-left: 2rem;
  padding-right: 2rem;


  @include media768(){
    padding-left: 9.996% !important;
    padding-right: 9.996% !important;
  }
}

@mixin kp-offset-1-in-10-col(){
  padding-left: 2rem;
  padding-right: 2rem;


  @include media768(){
    padding-left: 9.996% !important;
    padding-right: 9.996% !important;
  }
}

@mixin normalizeButton(){
  background-color: $none;
  border: 0;
  padding: 0;
  &:hover{
    background-color: $none;
  }
}

.padding-bottom-10{
  padding-bottom: 1rem;
}

//temp
.temp-add-tag-btn{
  position: absolute !important;
  right: -1rem;
  top: 0;
  transform: translateX(100%);
}

.show-dev-tools-btn{
  top: 0px !important;
  left: 0px !important;
  position: fixed !important;
  z-index: 100000000000000000 !important;
  background: red !important;
  color: white !important;
  font-size: 8px !important;
  border: 0 !important;
  padding: 0.5rem !important;
  height: auto !important;
}

.OKE-WarningScreen{
  position: fixed;
  padding: 2rem;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $red;
  color: $white;
  z-index: 100000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  img{
    margin-bottom: 1rem;
  }
  // &> * {
  //   margin-bottom: 1rem;
  // }

  .OKE-WarningScreen__link {
    text-decoration: underline;
    color: $white;
    cursor: pointer;
  }
}
