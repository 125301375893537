.Card-Wrapper--Featured3{
  // background-color: aqua; //temp
  
  .Card--Featured3{
    padding: 2rem;
    cursor: pointer;

    &:hover{ 
      .Card__title{
        color: $primaryColorText; 
      }

      .Card__title .Card__title-span{
        //animate the underline created by the mixin : underlineForAnimation
        background-size: 100% 100%;
      }
    }

    .Card__title{
      @include kp-clamp-text(4);
      margin-top: 1rem;
      margin-bottom: 1.5rem;
      @include kp-multi-transition(color);

      .Card__title-span{
        @include underlineForAnimation(5, 0.5s)
      }
    }

  }
  
}