.CommentsModule{
  border-top: 1px solid $greyColor10;
  padding-top: 2rem;

  @include mediaQuery('sm'){
    padding-top: 4rem;
  }

  .CommentsModule__titleSection{
    padding-bottom: 2rem;
  }
  .CommentsModule__input{
    padding-bottom: 2rem;
    @include mediaQuery('sm'){
      padding-bottom: 4rem;
    }

    .CommentsModule__inputEditor{
      .kp-rich-editor{
        padding: 2rem;
        height: 10rem;
        background-color: $greyColor5;
        border-radius: 2px;
        @include bodySans--M();
      }
    }
    .CommentsModule__submitInputBtn-wrapper{
      display: flex; 
      justify-content: flex-end;
    }
    .CommentsModule__submitInputBtn{
      margin-top: 2rem;
      text-align: right;
    }
  }
  

  .CommentsModule__listElem{
    border-bottom: 1px solid $greyColor10;
    padding: 2rem 0;

    .CommentsModule__commentMeta-wrapper{
      display: flex; 
      align-items: center; 
      height: 3rem;

      .CommentsModule__commenterName{
        .kp-link-content__text{
          @include bodySans--M--Bold();
        }
      }
    }

    .CommentsModule__displayEditor{
      .kp-rich-editor{
        @include bodySans--M();
      }
    }
  }
}


.CommentsModule__input__submit-btn-wrapper,
.CommentsModule__input__login-btn-wrapper{
  &.kp-btn-wrapper{
    justify-content: left;
    
  }

}

