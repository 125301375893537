


.kp-overlay-page-header__center-comp{
  flex: 3;
}


.overlayPageHeader{
  border-bottom: 1px solid $greyColor10;

  .overlayPageHeader-container{
  
    .overlayPageHeader-col{
      padding-top: 1rem;
      padding-bottom: 1rem;
      position: relative;
      height: 6rem;
      
      .overlayPageHeader__actionsWrapper{
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        display: flex;
        .overlayPageHeader__action{
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }
      }
      
  
  
      .overlayPageHeader__left-comp{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        left: 0;
        top: 0;
      }
  
      
  
      .overlayPageHeader__right-comp{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        right: 0;
        top: 0;
      }
  
    }
  }
}

